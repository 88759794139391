import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';

const ProtocolDetail = () => {
    const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
    const [protocol, setProtocol] = useState({});
    const [status, setStatus] = useState(protocol.status);
    const navigate = useNavigate();

    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState("");

    const { id } = useParams();

    // Check for authentication when the component mounts
    useEffect(() => {
        checkAuthenticated();
        getProtocol(id);
    }, []);

    // Submit review
    const handleSubmit = (e) => {
        e.preventDefault();

        if (comment.trim() === '') {
            setCommentError('Please leave a comment');
            return;
        }

        let review = { status, comment };

        if (protocol.number.search("A") === 5) {
            if (protocol.reviewer === state.user.id) {
                protocol.alt_comment === null ? review = { status: "partially reviewed", comment } : review = { status, comment };
            } else if (protocol.alt_reviewer === state.user.id) {
                protocol.comment === null ? review = { status: "partially reviewed", alt_comment: comment } : review = { status, alt_comment: comment };
            }
        }

        console.log(review);

        addReview(review);
    }

    // Get protocol
    const getProtocol = async (id) => {
        const response = await fetch(`https://app.mrcz.org.zw/api/protocols/protocol/${id}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch('https://app.mrcz.org.zw/api/token/refresh/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return getProtocol(id);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            const data = await response.json();
            console.log(data);
            setProtocol(data);
        }
    };

    // Post Review
    const addReview = async (review) => {
        const updated_protocol = { ...protocol, ...review }
        // Send a request with the current access token
        const response = await fetch(`https://app.mrcz.org.zw/api/protocols/protocol/${id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_protocol)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch('https://app.mrcz.org.zw/api/token/refresh/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return addReview(review);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);
            alert("Protocol successfully reviewed");
            navigate("/");
        }
    };



    return (
        <>
            <h1 className="my-4">Protocol: <span className='display-6'>#{protocol.number}</span></h1>
            <div className="card shadow p-3 mb-5 bg-white rounded">
                <h4 className='display-6'>A. Details of Research Team</h4>
                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.research_teams?.map((research_team, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Principle Investigator</strong>
                                        </h6>
                                        <span>{research_team.principal_investigator}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Nationality</strong>
                                        </h6>
                                        <span>{research_team.nationality}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Existing Qualifications</strong>
                                        </h6>
                                        <span>{research_team.existing_qualifications}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Academic Title</strong>
                                        </h6>
                                        <span>{research_team.academic_title}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Institution</strong>
                                        </h6>
                                        <span>{research_team.institution}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Postal Address</strong>
                                        </h6>
                                        <span>{research_team.postal_address}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Email</strong>
                                        </h6>
                                        <span>{research_team.email}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Telephone</strong>
                                        </h6>
                                        <span>{research_team.telephone}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Is this research expected to lead to the award of a higher degree for the PI or any other research team member?</strong>
                                        </h6>
                                        <span>{research_team.up_for_award}</span>
                                    </td>
                                </tr>
                                {research_team.up_for_award === "Yes" && <><tr>
                                    <td>
                                        <h6>
                                            <strong>Degree Type</strong>
                                        </h6>
                                        <span>{research_team.degree_type}</span>
                                    </td>
                                </tr>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>Student name</strong>
                                            </h6>
                                            <span>{research_team.student_name}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>University/Institution where student is registered</strong>
                                            </h6>
                                            <span>{research_team.student_varsity}</span>
                                        </td>
                                    </tr>
                                </>}
                                {(protocol.study_type === "PhD" || protocol.study_type === "MSc" || protocol.study_type === "BSc" || research_team.up_for_award === "Yes") &&
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>Student # and Year of Study</strong>
                                            </h6>
                                            <span>{research_team.student_info}</span>
                                        </td>
                                    </tr>}
                                <tr>
                                    <table className="table">
                                        <thead>
                                            <tr className='thead-light bg-light'>
                                                <th scope="col">Co-investigators Names</th>
                                                <th scope="col">Qualifications</th>
                                                <th scope="col">Institution/Department</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {protocol.co_investigators?.map((investigator, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{investigator.name}</td>
                                                        <td>{investigator.qualifications}</td>
                                                        <td>{investigator.department}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <br />
                <h4 className='display-6'>B. Attached Documents</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.documents?.map((document, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Research Proposal Summary</strong>
                                        </h6>
                                        <span>{document.proposal_summary?.substring(document.proposal_summary.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.proposal_summary} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Full Research Proposal</strong>
                                        </h6>
                                        <span>{document.full_proposal?.substring(document.full_proposal.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.full_proposal} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Informed Consent Form</strong>
                                        </h6>
                                        <span>{document.informed_consent_form?.substring(document.informed_consent_form.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.informed_consent_form} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                {(protocol.study_type === "PhD" || protocol.study_type === "Organisational Studies" || protocol.study_type === "Clinical Trials") && <>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>Specimen Storage and shipment consent form</strong>
                                            </h6>
                                            <span>{document.specimen_consent_form?.substring(document.specimen_consent_form.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.specimen_consent_form} target='_blank' rel="noreferrer">View</a></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>Name, contact details and detailed curriculum vitae of academic supervisor(s)</strong>
                                            </h6>
                                            <span>{document.supervisor_details?.substring(document.supervisor_details.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.supervisor_details} target='_blank' rel="noreferrer">View</a></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>For candidates registered with foreign institutions, please provide name contact details and letter from proposed local co-supervisor/adviser confirming willingness to supervise/advise</strong>
                                            </h6>
                                            <span>{document.co_supervisor_confirm?.substring(document.co_supervisor_confirm.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.co_supervisor_confirm} target='_blank' rel="noreferrer">View</a></span>
                                        </td>
                                    </tr></>}
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Data collection tools</strong>
                                        </h6>
                                        <span>{document.data_collection_tools?.substring(document.data_collection_tools.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.data_collection_tools} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                {(protocol.study_type === "PhD" || protocol.study_type === "Organisational Studies" || protocol.study_type === "Clinical Trials" || protocol.study_type === "MSc" || protocol.study_type === "BSc") && <><tr>
                                    <td>
                                        <h6>
                                            <strong>Letter of support from supervisor</strong>
                                        </h6>
                                        <span>{document.supervisor_letter?.substring(document.supervisor_letter.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.supervisor_letter} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>Proof of registration with learning institution</strong>
                                            </h6>
                                            <span>{document.proof_of_registration?.substring(document.proof_of_registration.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.proof_of_registration} target='_blank' rel="noreferrer">View</a></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>
                                                <strong>University Research Ethics Committee /IRB approval</strong>
                                            </h6>
                                            <span>{document.irb_approval?.substring(document.irb_approval.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.irb_approval} target='_blank' rel="noreferrer">View</a></span>
                                        </td>
                                    </tr></>}
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Drug brochure or Supplementary information as applicable</strong>
                                        </h6>
                                        <span>{document.supplementary_information?.substring(document.supplementary_information.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.supplementary_information} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Permission letter from Head of institution</strong>
                                        </h6>
                                        <span>{document.permission_letter?.substring(document.permission_letter.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.permission_letter} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Student or Principal Investigator's CV</strong>
                                        </h6>
                                        <span>{document.cv?.substring(document.cv.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.cv} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>
                                {(protocol.study_type === "Individual Research" || protocol.study_type === "Exempted from Ethics Review" || protocol.study_type === "Fast Track Reviews") && <tr>
                                    <td>
                                        <h6>
                                            <strong>Proof of funding on Sponsor’s Letterhead </strong>
                                        </h6>
                                        <span>{document.proof_of_funding?.substring(document.proof_of_funding.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={document.proof_of_funding} target='_blank' rel="noreferrer">View</a></span>
                                    </td>
                                </tr>}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <br />

                <h4 className='display-6'>C. Details of Research Coordinator</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.research_coordinators?.map((coordinator, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Name</strong>
                                        </h6>
                                        <span>{coordinator.name}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Postal Address</strong>
                                        </h6>
                                        <span>{coordinator.address}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>E-mail Address</strong>
                                        </h6>
                                        <span>{coordinator.email}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Telephone Number</strong>
                                        </h6>
                                        <span>{coordinator.telephone}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Mobile Number</strong>
                                        </h6>
                                        <span>{coordinator.mobile}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Site where Coordinator is stationed</strong>
                                        </h6>
                                        <span>{coordinator.site}</span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <br />

                <h4 className='display-6'>D. Details of Proposed Research</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        <tr>
                            <td>
                                <h6>
                                    <strong>Title</strong>
                                </h6>
                                <span>{protocol.title}</span>
                            </td>
                        </tr>
                        {protocol.proposed_research?.map((research, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Starting Date</strong>
                                        </h6>
                                        <span>{research.starting_date}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Ending Date</strong>
                                        </h6>
                                        <span>{research.ending_date}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Performance site(s) in Zimbabwe</strong>
                                        </h6>
                                        <span>{research.performance_site_in_zim}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Performance site(s) outside Zimbabwe</strong>
                                        </h6>
                                        <span>{research.performance_site_out_zim}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Total number of study personnel</strong>
                                        </h6>
                                        <span>{research.number_of_study_personnel}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Budget</strong>
                                        </h6>
                                        <span>{research.currency} {research.budget} </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Name and address of Funding agency</strong>
                                        </h6>
                                        <span>{research.funding_agency}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Status of funding</strong>
                                        </h6>
                                        <span>{research.funding_status}</span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                {protocol.collaborating_institutions && <><br />
                    <h4 className='display-6'>E. Contributing Institutions</h4>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Focal person</th>
                                <th scope="col">Telephone</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {protocol.collaborating_institutions?.map((collaborating_institution, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>{collaborating_institution.name}</td>
                                        <td>{collaborating_institution.focal_person}</td>
                                        <td>{collaborating_institution.telephone}</td>
                                        <td>{collaborating_institution.email}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table></>}
                <br />

                <h4 className='display-6'>F. Population</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.populations?.map((population, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Males</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.males} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Females</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.females} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Adolescents</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.adolescents} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Children</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.males} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Pregnant Women</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.pregnant_women} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Foetuses</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.foetuses} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Elderly</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.elderly} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Prisoners</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.prisoners} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Cognitively Impaired</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.cognitively_impaired} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Hospital Inpatients</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.hospital_inpatients} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Sexual Minorities</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.sexual_minorities} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Sex Workers</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={population.sex_workers} /></span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <br />

                <h4 className='display-6'>G. Study Type</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.study_types?.map((study_type, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Survey</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.survey} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Secondary</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.secondary} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Observational Clinical Trials</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.observational_clinical_trials} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Clinical Trial</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.clinical_trial} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Laboratory Research</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.lab_research} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Record Review</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.record_review} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Operations Research</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.operations_research} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Qualitative</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.qualitative} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Device Study</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={study_type.device_study} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Other</strong>
                                        </h6>
                                        <span>{study_type.other}</span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                <br />
                <h4 className='display-6'>H. Determination of Risk</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.risk_determinations?.map((risk_determination, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Human exposure to ionizing radiation</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.human_exposure_to_ionizing_radiation} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Fetal tissue or abortus</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.fetal_tissue} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Investigational new drug</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.investigational_new_drug} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Investigational new device</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.investigational_new_device} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Existing data available via public archives/sources</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.existing_data_available} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Existing data not available via public archives</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.existing_data_not_available} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Observation of public behavior</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.public_behavior_observation} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Is the information going to be recorded in such a way that participants can be identified?</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.information_recorded} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Does the research deal with sensitive aspects of the participants behavior, sexual behavior, alcohol use or illegal conduct such as drug use?</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.sensitive_aspects} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Could the information recorded about the individual if it became known outside of the research, place the participants at risk of criminal prosecution or civil liability?</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.criminal_prosecution_risk} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Could the information recorded about the individual if it became known outside of the research, damage the participant’s financial standing, reputation and employability?</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={risk_determination.damage_reputation} /></span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                <br />
                <h4 className='display-6'>I. Training</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.trainings?.map((training, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Research Ethics /Human Subjects Protection</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={training.research_ethics === "Yes"} /></span>
                                    </td>
                                    {training.research_ethics === "No" &&
                                        <td>
                                            <h6>
                                                <strong>Date when this will be done</strong>
                                            </h6>
                                            <span>{training.research_ethics_date}</span>
                                        </td>}
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>ICH-GCP</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={training.ich_gcp === "Yes"} /></span>
                                    </td>
                                    {training.ich_gcp === "No" &&
                                        <td>
                                            <h6>
                                                <strong>Date when this will be done</strong>
                                            </h6>
                                            <span>{training.ich_gcp_date}</span>
                                        </td>}
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Good Clinical Laboratory Practices</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={training.good_clinical_lab_practices === "Yes"} /></span>
                                    </td>
                                    {training.good_clinical_lab_practices === "No" &&
                                        <td>
                                            <h6>
                                                <strong>Date when this will be done</strong>
                                            </h6>
                                            <span>{training.good_clinical_lab_practices_date}</span>
                                        </td>}
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Good Data Management Practices</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={training.good_data_management_practices === "Yes"} /></span>
                                    </td>
                                    {training.good_data_management_practices === "No" &&
                                        <td>
                                            <h6>
                                                <strong>Date when this will be done</strong>
                                            </h6>
                                            <span>{training.good_data_management_practices_date}</span>
                                        </td>}
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Other</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={training.other === "Yes"} /></span>
                                    </td>
                                    {training.other === "No" &&
                                        <td>
                                            <h6>
                                                <strong>Date when this will be done</strong>
                                            </h6>
                                            <span>{training.other_date}</span>
                                        </td>}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                <br />
                <h4 className='display-6'>J. Conflict of Interest</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.conflicts?.map((conflict, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Conflict of Interest</strong>
                                        </h6>
                                        <span>{conflict.conflict_of_interest ? "Yes" : "No"}</span>
                                    </td>
                                    {conflict.conflict_of_interest &&
                                        <td>
                                            <h6>
                                                <strong>Potential Conflict Management Plan</strong>
                                            </h6>
                                            <span>{conflict.potential_conflict_management_plan?.substring(conflict.potential_conflict_management_plan.lastIndexOf('/') + 1)} <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={conflict.potential_conflict_management_plan} target='_blank' rel="noreferrer">View</a></span>
                                        </td>}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                <br />
                <h4 className='display-6'>K. Statistical Planning & Data Analysis</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.statistical_plannings?.map((statistical_planning, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Has this project been reviewed by a professional statistician</strong>
                                        </h6>
                                        <span>{statistical_planning.reviewed ? "Yes" : "No"}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Details</strong>
                                        </h6>
                                        <span>{statistical_planning.details}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Proposed sample size</strong>
                                        </h6>
                                        <span>{statistical_planning.sample_size}</span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                <br />
                <h4 className='display-6'>L. Consent Process</h4>

                <table className="table table-bordered table-condensed">
                    <tbody>
                        {protocol.consent_processes?.map((consent_process, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Does the research include the consent form?</strong>
                                        </h6>
                                        <span>{consent_process.has_consent_form}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Written</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={consent_process.written} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Verbal/Oral</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={consent_process.verbal} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Consent Language: English</strong>
                                        </h6>
                                        <span><input className='form-check-input' type='checkbox' checked={consent_process.english} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Local languages</strong>
                                        </h6>
                                        <span>{consent_process.local_languages}</span>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                <br />
                <h4 className='display-6'>M. Clinical Trials</h4>

                {protocol.clinical_trials?.map((clinical_trial, index) => (
                    <React.Fragment key={index}>
                        <table className="table table-bordered table-condensed">
                            <tbody>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Has Medicines Control Authority of Zimbabwe (MCAZ) approval been applied for?</strong>
                                        </h6>
                                        <span>{clinical_trial.mcaz_applied}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>
                                            <strong>Is the PI presently involved in other research and/or clinical trial activities?</strong>
                                        </h6>
                                        <span>{clinical_trial.pi_in_other_researches}</span>
                                    </td>
                                </tr>
                                {clinical_trial.pi_in_other_researches === "Yes" &&
                                    <>

                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Please provide info</strong>
                                                </h6>
                                                <span>{clinical_trial.info}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Investigational drug(s)</strong>
                                                </h6>
                                                <span><input className='form-check-input' type='checkbox' checked={clinical_trial.investigational_drugs} /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>New therapeutic applications of MCAZ approved drug(s)</strong>
                                                </h6>
                                                <span><input className='form-check-input' type='checkbox' checked={clinical_trial.therapeutic_applications} /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>New combination of any of the above</strong>
                                                </h6>
                                                <span><input className='form-check-input' type='checkbox' checked={clinical_trial.new_combination} /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Medical device</strong>
                                                </h6>
                                                <span><input className='form-check-input' type='checkbox' checked={clinical_trial.medical_device} /></span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Brief description of how this drug or device is a part of the proposed investigation</strong>
                                                </h6>
                                                <span>{clinical_trial.drug_description}</span>
                                            </td>
                                        </tr>

                                        <tr className='fw-bold fst-italic'>Drug(s) or device(s) to be used:</tr>
                                        <table className="table">
                                            <thead>
                                                <tr className='thead-light bg-light'>
                                                    <th scope="col">Generic Name</th>
                                                    <th scope="col">Trade or Brand Name</th>
                                                    <th scope="col">Manufacturer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {protocol.drugs?.map((drug, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td>{drug.generic_name}</td>
                                                            <td>{drug.brand}</td>
                                                            <td>{drug.manufacturer}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>

                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>The risks, hazards, known contraindications</strong>
                                                </h6>
                                                <span>{clinical_trial.risks}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Reasons for choice of drug(s) for this study</strong>
                                                </h6>
                                                <span>{clinical_trial.drug_reason}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Dose schedule, route of administration, and duration of therapy</strong>
                                                </h6>
                                                <span>{clinical_trial.dose_schedule}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6>
                                                    <strong>Assessment of patient while receiving therapy including clinical observations and laboratory tests</strong>
                                                </h6>
                                                <span>{clinical_trial.patient_assessment}</span>
                                            </td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </table>
                    </React.Fragment>
                ))}
            </div>

            {protocol.comments?.map((comment, index) => (
                comment.cover_letter && <React.Fragment key={index}>
                    <div className="ms-panel ms-widget ms-crypto-widget card border-left-primary shadow h-100 py-2 px-5 mb-5">
                        <div className="ms-panel">
                            <div className="ms-panel-header my-3">
                                <h4>Responses</h4>
                            </div>
                            <hr />
                            <div className="ms-panel-body p-0">
                                <div className="row">
                                    <div className="col">
                                        <p>Cover Letter: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={comment.cover_letter} target='_blank' rel="noreferrer">{comment.cover_letter?.substring(comment.cover_letter.lastIndexOf('/') + 1)}</a></p>
                                        <p>Response to comments:  <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2" href={comment.response} target='_blank' rel="noreferrer">{comment.response?.substring(comment.response.lastIndexOf('/') + 1)}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}

            <form onSubmit={handleSubmit}>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="form-floating">
                        <textarea
                            className={`form-control form-control-lg ${commentError !== '' ? 'is-invalid' : ''}`}
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            style={{ height: '100px' }}
                            value={comment}
                            onChange={(e) => {
                                setComment(e.target.value);
                                setCommentError('');
                            }}
                        >
                        </textarea>
                        {commentError && <div className="invalid-feedback">{commentError}</div>}
                        <label htmlFor="floatingTextarea2">Comment</label>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary fw-medium py-2 rounded-1" onClick={() => setStatus("reviewed")}>
                        Comment
                    </button>
                </div>
            </form>
        </>
    );
}

export default ProtocolDetail;