import { useNavigate } from "react-router-dom";

const Protocol = ({protocol}) => {
    const navigate = useNavigate();
    var badge_bg = 'bg-dark';

    const handleViewClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    const handleRespondClick = () => {
        navigate(`/response-to-comments/${protocol.id}`, { state: { protocol } });
    };

    
    (() => {
        switch (protocol.status) {
            case 'pending':
                badge_bg = 'bg-warning';
                break;
            case 'declined':
                badge_bg = 'bg-info';
                break;  
            case 'assigned':
                badge_bg = 'bg-secondary text-white';
                break;
            case 'reviewed':
                badge_bg = 'bg-primary text-white';
                break;
            case 'partially reviewed':
                badge_bg = 'bg-primary-subtle';
                break;
            case 'accepted':
                badge_bg = 'bg-success text-white';
                break;
            case 'rejected':
                badge_bg = 'bg-danger text-white';
                break;
            case 'response':
                badge_bg = 'bg-dark text-white';
                break;
            default:
                return null;
        }
    })()
    

    
    return (
        <tr>
            <td>{protocol.number}</td>
            <td>{protocol.title}</td>
            <td><span className={`${badge_bg} text-center rounded-1 text-small p-1 fw-medium`}>{protocol.status}</span></td>
            <td>{protocol.created_date}</td>
            <td>
                <button type="button" className="btn btn-secondary rounded-1 me-2 fw-medium" onClick={handleViewClick}>View</button>
                {protocol.status === "rejected" && <button type="button" className="btn btn-primary rounded-1 me-2 fw-medium" onClick={handleRespondClick}>Respond</button>}
            </td>
        </tr>
      );
}
 
export default Protocol;