import { useState } from "react";


const ResearchCoordinator = ({onGetCoordinator, onPrev}) => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [telephone, setTelephone] = useState("");
    const [telephoneError, setTelephoneError] = useState("");

    const [mobile, setMobile] = useState("");
    const [mobileError, setMobileError] = useState("");

    const [site, setSite] = useState("");
    const [siteError, setSiteError] = useState("");
    
    const handleInputChange = (event, setInput, setInputError) => {
        setInput(event.target.value);
        setInputError("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(name.trim() === ""){
            setNameError("Enter the name");
            return;
        }

        if(address.trim() === ""){
            setAddressError("Enter the postal address");
            return;
        }

        if(email.trim() === ""){
            setEmailError("Enter the email address");
            return;
        }

        if(telephone.trim() === ""){
            setTelephoneError("Enter the telephone number");
            return;
        }

        if (!/^\+\d{1,4}\d{10}$/.test(telephone)) {
            setTelephoneError('Please enter valid telephone number');
            return;
        }

        if(mobile.trim() === ""){
            setMobileError("Enter the mobile number");
            return;
        }

        if (!/^\+\d{1,4}\d{10}$/.test(mobile)) {
            setMobileError('Please enter valid mobile number');
            return;
        }

        if(site.trim() === ""){
            setSiteError("Enter the site");
            return;
        }

        const research_coordinator = {name, address, email, telephone, mobile, site};

        onGetCoordinator(research_coordinator);      
    }

    return (
        <>
            <h1 className="my-4">Details of Research Coordinator</h1>
            <form onSubmit={handleSubmit} method="post">
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <table className="table">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>Name</td>
                            <td>
                                <input type="text" className={`form-control ${nameError !== ''  ? 'is-invalid' : ''}`} id="name" name='name' value={name} onChange={(event) => handleInputChange(event, setName, setNameError)}/>
                                {nameError && <div className="invalid-feedback">{nameError}</div>}
                            </td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>Postal address</td>
                            <td>
                                <input type="text" className={`form-control ${addressError !== ''  ? 'is-invalid' : ''}`} id="address" name='address' value={address} onChange={(event) => handleInputChange(event, setAddress, setAddressError)}/>
                                {addressError && <div className="invalid-feedback">{addressError}</div>}
                            </td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td>E-mail Address</td>
                            <td>
                                <input type="email" className={`form-control ${emailError !== ''  ? 'is-invalid' : ''}`} id="email" name='email' value={email} onChange={(event) => handleInputChange(event, setEmail, setEmailError)}/>
                                {emailError && <div className="invalid-feedback">{emailError}</div>}
                            </td>
                            </tr>               
                            <tr>
                            <td>4</td>
                            <td>Telephone Number</td>
                            <td>
                                <input type="text" className={`form-control ${telephoneError !== ''  ? 'is-invalid' : ''}`} id="telephone" name='telephone' value={telephone} onChange={(event) => handleInputChange(event, setTelephone, setTelephoneError)}/>
                                {telephoneError && <div className="invalid-feedback">{telephoneError}</div>}
                            </td>
                            </tr>
                            <tr>
                            <td>5</td>
                            <td>Mobile Number</td>
                            <td>
                                <input type="text" className={`form-control ${mobileError !== ''  ? 'is-invalid' : ''}`} id="mobile" name='mobile' value={mobile} onChange={(event) => handleInputChange(event, setMobile, setMobileError)}/>
                                {mobileError && <div className="invalid-feedback">{mobileError}</div>}
                            </td>
                            </tr>
                            <tr>
                            <td>6</td>
                            <td>Site where Coordinator is stationed</td>
                            <td>
                                <input type="text" className={`form-control ${siteError !== ''  ? 'is-invalid' : ''}`} id="site" name='site' value={site} onChange={(event) => handleInputChange(event, setSite, setSiteError)}/>
                                {siteError && <div className="invalid-feedback">{siteError}</div>}
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                        Previous
                    </button>
                    <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                        Next
                    </button>
                </div>
            </form>
        </>

    );
}
 
export default ResearchCoordinator;