import { useState } from "react";


const ClinicalTrials = ({clinical_trial, drugs, onUpdateClinical}) => {
    const rows = [];
    const [mcaz_applied, setMcazApplied] = useState(clinical_trial.mcaz_applied);
    const [mcazAppliedError, setMcazAppliedError] = useState("");

    const [pi_in_other_researches, setPiInOtherResearches] = useState(clinical_trial.pi_in_other_researches);
    const [piInOtherResearchesError, setPiInOtherResearchesError] = useState("");

    const [investigational_drugs, setInvestigationalDrugs] = useState(clinical_trial.investigational_drugs);
    const [therapeutic_applications, setTherapeuticApps] = useState(clinical_trial.therapeutic_applications);
    const [new_combination, setNewCombination] = useState(clinical_trial.new_combination);
    const [medical_device, setMedicalDevice] = useState(clinical_trial.medical_device);

    const [info, setInfo] = useState(clinical_trial.info);
    const [infoError, setInfoError] = useState("");

    const [drug_description, setDrugDescription] = useState(clinical_trial.drug_description);
    const [drugDescriptionError, setDrugDescriptionError] = useState("");

    const [risks, setRisks] = useState(clinical_trial.risks);
    const [risksError, setRisksError] = useState("");

    const [drug_reason, setDrugReason] = useState(clinical_trial.drug_reason);
    const [drugReasonError, setDrugReasonError] = useState("");

    const [dose_schedule, setDoseSchedule] = useState(clinical_trial.dose_schedule);
    const [doseScheduleError, setDoseScheduleError] = useState("");

    const [patient_assessment, setPatientAssessment] = useState(clinical_trial.patient_assessment);
    const [patientAssessmentError, setPatientAssessmentError] = useState("");

    const [drugErrors, setDrugErrors] = useState({});

    const handleInputChange = (event, setText, setTextError) => {
        setText(event.target.value);
        setTextError("");
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (mcaz_applied.trim() === '') {
            setMcazAppliedError('Select option');
            return;
        }

        if (pi_in_other_researches.trim() === '') {
            setPiInOtherResearchesError('Select option');
            return;
        }

        if (pi_in_other_researches === "No"){
            onUpdateClinical({id: clinical_trial.id, protocol: clinical_trial.protocol, mcaz_applied, pi_in_other_researches});
            return
        }

        if (info.trim() === '') {
            setInfoError('Enter the info');
            return;
        }

        if (drug_description.trim() === '') {
            setDrugDescriptionError('Enter the description');
            return;
        }

        const updated_drugs = [];
        const errors = {};

        for (let i = 0; i < drugs.length; i++) {
          const generic_name = e.target.elements[`generic_name[${i}]`].value ? e.target.elements[`generic_name[${i}]`].value : drugs[i].generic_name ;
          const brand = e.target.elements[`brand[${i}]`].value ? e.target.elements[`brand[${i}]`].value : drugs[i].brand;
          const manufacturer = e.target.elements[`manufacturer[${i}]`].value ? e.target.elements[`manufacturer[${i}]`].value : drugs[i].manufacturer;
    
          if (generic_name.trim() === "") {
            errors[`generic_name[${i}]`] = "Enter the generic name";
          }

          if (brand.trim() === "") {
            errors[`brand[${i}]`] = "Enter the trade or brand name";
          }

          if (manufacturer.trim() === "") {
            errors[`manufacturer[${i}]`] = "Enter the manufacturer";
          }
          
          updated_drugs.push({id: drugs[i].id, protocol: drugs[i].protocol, generic_name, brand, manufacturer});
        }

        if (Object.keys(errors).length !== 0) {
            setDrugErrors(errors);
            return;
        }

        if (risks.trim() === '') {
            setRisksError('Enter the risks');
            return;
        }

        if (drug_reason.trim() === '') {
            setDrugReasonError('Enter the reasons for choice of drug(s)');
            return;
        }

        if (dose_schedule.trim() === '') {
            setDoseScheduleError('Enter the dose schedule, route of administration and duration of therapy');
            return;
        }

        if (patient_assessment.trim() === '') {
            setPatientAssessmentError('Enter the description assessment of patient)');
            return;
        }

        onUpdateClinical({id: clinical_trial.id, protocol: clinical_trial.protocol, mcaz_applied, pi_in_other_researches, investigational_drugs, therapeutic_applications, new_combination, medical_device, info, drug_description, risks, drug_reason, dose_schedule, patient_assessment}, updated_drugs); 
        
    }

    const renderInputClassName = (fieldName) => {
        return drugErrors[fieldName] ? "form-control is-invalid" : "form-control";
      };
    
    const renderErrorMessage = (fieldName) => {
        return drugErrors[fieldName] && (
            <div className="invalid-feedback">{drugErrors[fieldName]}</div>
        );
    };

    for (let i = 0; i < drugs.length; i++) {
        rows.push(
            <td key={i}>
                <input type="text" className={renderInputClassName(`generic_name[${i}]`)} name={`generic_name[${i}]`} placeholder={drugs[i].generic_name}/>
                {renderErrorMessage(`generic_name[${i}]`)}
                <br/>
                <input type="text" className={renderInputClassName(`brand[${i}]`)} name={`brand[${i}]`} placeholder={drugs[i].brand}/>
                {renderErrorMessage(`brand[${i}]`)}
                <br/>
                <input type="text" className={renderInputClassName(`manufacturer[${i}]`)} name={`manufacturer[${i}]`} placeholder={drugs[i].manufacturer}/>
                {renderErrorMessage(`manufacturer[${i}]`)}
                <br/>
            </td>
        );
    }

    return(
        <form onSubmit={handleSubmit}>
            <div className="shadow bg-secondary-subtle p-3 mb-5 rounded">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                        <tr>
                        <td>Has Medicines Control Authority of Zimbabwe (MCAZ) approval been applied for?</td>
                        <td>
                            <select id="mcaz_applied" name="mcaz_applied" className={`form-select ${mcazAppliedError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" value={mcaz_applied} onChange={(event) => handleInputChange(event, setMcazApplied, setMcazAppliedError)}>
                                <option value="" disabled hidden>Select ...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>                               
                            </select>
                            {mcazAppliedError && <div className="invalid-feedback">{mcazAppliedError}</div>}
                        </td>
                        </tr>
                    </tbody>
                    <tbody id='clinical_traya'>
                        <tr>
                        <td>Is the PI presently involved in other research and/or clinical trial activities?  (If yes, please provide details and % time allocated to each below)  </td>
                        <td>
                            <select id="pi_in_other_researches" name="pi_in_other_researches" className={`form-select ${piInOtherResearchesError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" value={pi_in_other_researches} onChange={(event) => handleInputChange(event, setPiInOtherResearches, setPiInOtherResearchesError)}>
                                <option value="" disabled hidden>Select ...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>                               
                            </select>
                            {piInOtherResearchesError && <div className="invalid-feedback">{piInOtherResearchesError}</div>}
                        </td>
                        </tr>
                        {pi_in_other_researches === "Yes" &&
                        <>
                        <tr id="pi_explan">
                        <td>Please provide info</td>
                        <td>
                            <input type="text" className={`form-control ${infoError !== ''  ? 'is-invalid' : ''}`} name="info" value={info} onChange={(event) => handleInputChange(event, setInfo, setInfoError)}/>
                            {infoError && <div className="invalid-feedback">{infoError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Which of the following will be used?</td>
                        </tr>
                        <tr>
                        <td>a. &nbsp;&nbsp;&nbsp;&nbsp; investigational drug(s)</td>
                        <td>
                            <input type="checkbox" className="form-check-input" name="investigational_drug" checked={investigational_drugs} onChange={() => setInvestigationalDrugs(!investigational_drugs)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>b. &nbsp;&nbsp;&nbsp;&nbsp; new therapeutic applications of MCAZ approved drug(s)</td>
                        <td>
                            <input type="checkbox" className="form-check-input" name="therapeutic_applications" checked={therapeutic_applications} onChange={() => setTherapeuticApps(!therapeutic_applications)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>c. &nbsp;&nbsp;&nbsp;&nbsp; new combination of any of the above</td>
                        <td>
                            <input type="checkbox" className="form-check-input" name="new_combination" checked={new_combination} onChange={() => setNewCombination(!new_combination)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>d. &nbsp;&nbsp;&nbsp;&nbsp; medical device</td>
                        <td>
                            <input type="checkbox" className="form-check-input" name="medical_device" checked={medical_device} onChange={() => setMedicalDevice(!medical_device)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Briefly describe how this drug or device is a part of the proposed investigation.</td>
                        <td>
                            <textarea className={`form-control ${drugDescriptionError !== ''  ? 'is-invalid' : ''}`} name="proposal_inv" rows="7" value={drug_description} onChange={(event) => handleInputChange(event, setDrugDescription, setDrugDescriptionError)}></textarea>
                            {drugDescriptionError && <div className="invalid-feedback">{drugDescriptionError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>For each drug or device to be used, please provide the following information:</td>
                            {rows}
                        </tr>
                        <tr>
                        <td>Please give the risks, hazards, known contraindications.</td>
                        <td>
                            <textarea className={`form-control ${risksError !== ''  ? 'is-invalid' : ''}`} name="risks" rows="7" value={risks} onChange={(event) => handleInputChange(event, setRisks, setRisksError)}></textarea>
                            {risksError && <div className="invalid-feedback">{risksError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Please give reasons for choice of drug(s) for this study.  Include pertinent animal clinical tests or appropriate citations.</td>
                        <td>
                            <textarea className={`form-control ${drugReasonError !== ''  ? 'is-invalid' : ''}`} name="drug_reason" rows="7" value={drug_reason} onChange={(event) => handleInputChange(event, setDrugReason, setDrugReasonError)}></textarea>
                            {drugReasonError && <div className="invalid-feedback">{drugReasonError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Please provide dose schedule, route of administration, and duration of therapy.</td>
                        <td>
                            <textarea className={`form-control ${doseScheduleError !== ''  ? 'is-invalid' : ''}`} name="dose_schedule" rows="7" value={dose_schedule} onChange={(event) => handleInputChange(event, setDoseSchedule, setDoseScheduleError)}></textarea>
                            {doseScheduleError && <div className="invalid-feedback">{doseScheduleError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Please describe assessment of patient while receiving therapy including clinical observations and laboratory tests.</td>
                        <td>
                            <textarea className={`form-control ${patientAssessmentError !== ''  ? 'is-invalid' : ''}`} name="patient_assessment" rows="7" value={patient_assessment} onChange={(event) => handleInputChange(event, setPatientAssessment, setPatientAssessmentError)}></textarea>
                            {patientAssessmentError && <div className="invalid-feedback">{patientAssessmentError}</div>}
                        </td>
                        </tr>
                        </>
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>
        </form>
    )
}

export default ClinicalTrials;