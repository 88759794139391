import { useState } from "react";


const Training = ({training, onUpdateTraining}) => {
    const [research_ethics, setResearchEthics] = useState(training.research_ethics);

    const [ich_gcp, setIchGcp] = useState(training.ich_gcp);

    const [good_clinical_lab_practices, setGoodClinicalLabPractices] = useState(training.good_clinical_lab_practices);

    const [good_data_management_practices, setGoodDataManagementPractices] = useState(training.good_data_management_practices);

    const [other, setOther] = useState(training.other);

    const [research_ethics_date, setResearchEthicsDate] = useState(training.research_ethics_date ? training.research_ethics_date: "" );
    const [researchEthicsDateError, setResearchEthicsDateError] = useState("");
    
    const [ich_gcp_date, setIchGcpDate] = useState(training.ich_gcp_date ? training.ich_gcp_date : "");
    const [ichGcpDateError, setIchGcpDateError] = useState("");

    const [good_clinical_lab_practices_date, setGoodClinicalLabPracticesDate] = useState(training.good_clinical_lab_practices_date ? training.good_clinical_lab_practices_date : "");
    const [goodClinicalLabPracticesDateError, setGoodClinicalLabPracticesDateError] = useState("");

    const [good_data_management_practices_date, setGoodDataManagementPracticesDate] = useState(training.good_data_management_practices_date ? training.good_data_management_practices_date : "");
    const [goodDataManagementPracticesDateError, setGoodDataManagementPracticesDateError] = useState("");
    
    const [other_date, setOtherDate] = useState(training.other_date ? training.other_date : "");
    const [otherDateError, setOtherDateError] = useState("");

    const handleInputChange = (event, setText) => {
        setText(event.target.value);
    }

    const handleInputDateChange = (event, setDate, setDateError) => {
        setDate(event.target.value);
        setDateError("");
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let updatedTraining = {id: training.id, protocol: training.protocol, research_ethics, ich_gcp, good_clinical_lab_practices, good_data_management_practices, other};

        if (research_ethics === "No") {
            if(research_ethics_date.trim() === ""){
                setResearchEthicsDateError('Select date');
                return
            }
            updatedTraining = {...updatedTraining, research_ethics_date};
        }

        if (ich_gcp === "No") {
            if (ich_gcp_date.trim() === "") {
                setIchGcpDateError('Select date');
                return
            }
            updatedTraining = {...updatedTraining, ich_gcp_date};
        }

        if (good_clinical_lab_practices === "No") {
            if (good_clinical_lab_practices_date === "") {
                setGoodClinicalLabPracticesDateError('Select date');
                return
            }
            updatedTraining = {...updatedTraining, good_clinical_lab_practices_date};
        }

        if (good_data_management_practices === "No") {
            if (good_data_management_practices_date === "") {
                setGoodDataManagementPracticesDateError('Select date');
                return
            }
            updatedTraining = {...updatedTraining, good_data_management_practices_date};
        }

        if (other === "No") {
            if (other_date === ""){
                setOtherDateError('Select date');
                return
            }
            updatedTraining = {...updatedTraining, other_date};
        }

        onUpdateTraining(updatedTraining);
    }


    return(
        <form onSubmit={handleSubmit}>
            <div className="bg-secondary-subtle shadow p-3 mb-5 rounded"> 
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                        <th>Has the research team undergone training in the following as appropriate: </th>
                        <th>YES</th>
                        <th>NO</th>
                        <th>If no please give dates when this will be done</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Research Ethics /Human Subjects Protection</td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="research_ethics" value="Yes" checked={research_ethics === "Yes"} onChange={(event) => handleInputChange(event, setResearchEthics)} required/>
                        </td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="research_ethics" value="No" checked={research_ethics === "No"} onChange={(event) => handleInputChange(event, setResearchEthics)} required/>
                        </td>
                        <td>
                            {research_ethics === "No" && <input type="date" className={`form-control date_set ${researchEthicsDateError !== ''  ? 'is-invalid' : ''}`} name="research_ethics_date" value={research_ethics_date} onChange={(event) => handleInputDateChange(event, setResearchEthicsDate, setResearchEthicsDateError)}/>}
                            {researchEthicsDateError && <div className="invalid-feedback">{researchEthicsDateError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>ICH-GCP</td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="ich_gcp" value="Yes"  checked={ich_gcp === "Yes"} onChange={(event) => handleInputChange(event, setIchGcp)} required/>
                        </td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="ich_gcp" value="No"  checked={ich_gcp === "No"} onChange={(event) => handleInputChange(event, setIchGcp)} required/>
                        </td>
                        <td>
                            {ich_gcp === "No" && <input type="date" className={`form-control date_set ${ichGcpDateError !== ''  ? 'is-invalid' : ''}`} name="ich_gcp_date" value={ich_gcp_date} onChange={(event) => handleInputDateChange(event, setIchGcpDate, setIchGcpDateError)}/>}
                            {ichGcpDateError && <div className="invalid-feedback">{ichGcpDateError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Good Clinical Laboratory Practices</td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="good_clinical_lab_practices" value="Yes"  checked={good_clinical_lab_practices === "Yes"} onChange={(event) => handleInputChange(event, setGoodClinicalLabPractices)} required/>
                        </td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="good_clinical_lab_practices" value="No"  checked={good_clinical_lab_practices === "No"} onChange={(event) => handleInputChange(event, setGoodClinicalLabPractices)} required/>
                        </td>
                        <td>
                            {good_clinical_lab_practices === "No" && <input type="date" className={`form-control date_set ${goodClinicalLabPracticesDateError !== ''  ? 'is-invalid' : ''}`} name="good_clinical_lab_practices_date" value={good_clinical_lab_practices_date} onChange={(event) => handleInputDateChange(event, setGoodClinicalLabPracticesDate, setGoodClinicalLabPracticesDateError)}/>}
                            {goodClinicalLabPracticesDateError && <div className="invalid-feedback">{goodClinicalLabPracticesDateError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Good Data Management Practices</td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="good_data_management_practices" value="Yes" checked={good_data_management_practices === "Yes"} onChange={(event) => handleInputChange(event, setGoodDataManagementPractices)} required/>
                        </td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="good_data_management_practices" value="No" checked={good_data_management_practices === "No"} onChange={(event) => handleInputChange(event, setGoodDataManagementPractices)} required/>
                        </td>
                        <td>
                            {good_data_management_practices === "No" && <input type="date" className={`form-control date_set ${goodDataManagementPracticesDateError !== ''  ? 'is-invalid' : ''}`} name="good_data_management_practices_date" value={good_data_management_practices_date} onChange={(event) => handleInputDateChange(event, setGoodDataManagementPracticesDate, setGoodDataManagementPracticesDateError)}/>}
                            {goodDataManagementPracticesDateError && <div className="invalid-feedback">{goodDataManagementPracticesDateError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td id='add_other'>Other (Specify if the team has taken any other similar/equivalent training)</td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="other" value="Yes"  checked={other === "Yes"} onChange={(event) => handleInputChange(event, setOther)} required/>
                        </td>
                        <td align="center">
                            <input className="form-check-input" type="radio" name="other" value="No" checked={other === "No"} onChange={(event) => handleInputChange(event, setOther)} required/>
                        </td>
                        <td>
                            {other === "No" && <input type="date" className={`form-control date_set ${otherDateError !== ''  ? 'is-invalid' : ''}`} name="other_date" value={other_date} onChange={(event) => handleInputDateChange(event, setOtherDate, setOtherDateError)}/>}
                            {otherDateError && <div className="invalid-feedback">{otherDateError}</div>}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>   
            <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>
        </form>
    )
}

export default Training;