import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="font-weight-bold text-muted">Copyright &copy; 2023 <Link to="https://www.webentangled.com/" target="_blank" rel="noreferrer">WebEntangled</Link>. All rights reserved</div>
                </div>
            </div>
        </footer>
     );
}
 
export default Footer;