import { useState } from "react";
import FileInput from "../../layout/FileInput";

const AttachDocuments = ({onGetDocs, onPrev, study_type}) => {

  const [proposal_summary, setProposalSummary] = useState(null);
  const [proposalSummaryError, setProposalSummaryError] = useState("");

  const [full_proposal, setFullProposal] = useState(null);
  const [fullProposalError, setFullProposalError] = useState("");

  const [informed_consent_form, setInformedConsentForm] = useState(null);
  const [informedConsentFormError, setInformedConsentFormError] = useState("");

  const [specimen_consent_form, setSpecimenConsentForm] = useState(null);
  const [specimenConsentFormError, setSpecimenConsentFormError] = useState("");

  const [data_collection_tools, setDataCollectionTools] = useState(null);
  const [dataCollectionToolsError, setDataCollectionToolsError] = useState("");

  const [supervisor_details, setSupervisorDetails] = useState(null);
  const [supervisorDetailsError, setSupervisorDetailsError] = useState("");

  const [co_supervisor_confirm, setCoSupervisorConfirm] = useState(null);
  const [coSupervisorConfirmError, setCoSupervisorConfirmError] = useState("");

  const [supervisor_letter, setSupervisorLetter] = useState(null);
  const [supervisorLetterError, setSupervisorLetterError] = useState("");

  const [proof_of_registration, setProofOfRegistration] = useState(null);
  const [proofOfRegistrationError, setProofOfRegistrationError] = useState("");

  const [irb_approval, setIrbApproval] = useState(null);
  const [irbApprovalError, setIrbApprovalError] = useState("");

  const [permission_letter, setPermissionLetter] = useState(null);
  const [permissionLetterError, setPermissionLetterError] = useState("");

  const [supplementary_information, setSupplementaryInformation] = useState(null);

  const [cv, setCv] = useState(null);
  const [cvError, setCvError] = useState("");

  const [proof_of_funding, setProofOfFunding] = useState(null);
  const [proofOfFundingError, setProofOfFundingError] = useState("");

  const handleInputChange = (event, setFile, setFileError) => {
    const file = event.target.files[0];
    setFile(file);
    setFileError("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (proposal_summary === null) {
      setProposalSummaryError('Attach the Research Proposal Summary');
      return
    }

    if (full_proposal === null) {
      setFullProposalError('Attach the Full Research Proposal');
      return
    }

    if (informed_consent_form === null) {
      setInformedConsentFormError('Attach the Informed Consent Form');
      return
    }

    if (data_collection_tools === null) {
      setDataCollectionToolsError('Attach the Data Collection Tools');
      return
    }

    if (permission_letter === null) {
      setPermissionLetterError('Attach the Permission letter from head of Institution');
      return
    }

    if (cv === null) {
      setCvError('Attach the CV');
      return
    }

    let docs = {proposal_summary, full_proposal, informed_consent_form, data_collection_tools, permission_letter, supplementary_information, cv};
    
    if (study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials") {
      if (specimen_consent_form === null) {
        setSpecimenConsentFormError('Attach the Specimen Storage and Shipment consent form');
        return
      }

      if (supervisor_details === null) {
        setSupervisorDetailsError('Attach the academic supervisor details');
        return
      }

      if (co_supervisor_confirm === null) {
        setCoSupervisorConfirmError('Attach the local co-supervisor/adviser details');
        return
      }

      docs = {...docs, specimen_consent_form, supervisor_details, co_supervisor_confirm};
    }

    if (study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials" || study_type === "MSc" || study_type === "BSc") {
      if (supervisor_letter === null) {
        setSupervisorLetterError('Attach the letter of support from supervisor');
        return
      }

      if (proof_of_registration === null) {
        setProofOfRegistrationError('Attach the proof of registration');
        return
      }

      if (irb_approval === null) {
        setIrbApprovalError('Attach the University Research Ethics Committee /IRB approval');
        return
      }

      docs = {...docs, supervisor_letter, proof_of_registration, irb_approval};
    }

    if (study_type === "Individual Research" || study_type === "Exempted from Ethics Review" || study_type === "Fast Track Reviews") {
      if (proof_of_funding === null) {
        setProofOfFundingError('Attach the proof of funding');
        return
      }
      docs = {...docs, proof_of_funding};
    }
    
    onGetDocs(docs);
  }

  return ( 
      <>
        <h1 className="mt-4">Attach Documents</h1>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="form-group">
              <div className="d-grid gap-2">
                <div className="mb-4">
                  <label htmlFor="proposal_summary" className="form-label fs-5 fw-medium">Research Proposal Summary <em>(maximum 4 pages)</em></label>
                  <input className={`form-control bg-light ${proposalSummaryError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="proposal_summary" name="proposal_summary" onChange={(event) => handleInputChange(event, setProposalSummary, setProposalSummaryError)}/>
                  {/* <FileInput id="proposal_summary" error={proposal_summary} setFile={setProposalSummary} setFileError={setProposalSummaryError} handleInputChange={handleInputChange}/> */}
                  {proposalSummaryError && <div className="invalid-feedback">{proposalSummaryError}</div>}
                </div>

                <div className="mb-4">
                  <label htmlFor="full_proposal" className="form-label fs-5 fw-medium">Full Research Proposal</label>
                  <input className={`form-control bg-light ${fullProposalError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="full_proposal" name="full_proposal" onChange={(event) => handleInputChange(event, setFullProposal, setFullProposalError)}/>
                  {fullProposalError && <div className="invalid-feedback">{fullProposalError}</div>}
                </div>
                
                <div className="mb-4">
                  <label htmlFor="informed_consent_form" className="form-label fs-5 fw-medium">Informed Consent Form</label>
                  <input className={`form-control bg-light ${informedConsentFormError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="informed_consent_form" name="informed_consent_form" onChange={(event) => handleInputChange(event, setInformedConsentForm, setInformedConsentFormError)}/>
                  {informedConsentFormError && <div className="invalid-feedback">{informedConsentFormError}</div>}
                </div>

                <div className="mb-4">
                  <label htmlFor="data_collection_tools" className="form-label fs-5 fw-medium">Data collection tools: <em>English & Vernacular Versions. (Shona/ Ndebele/ appropriate language)</em></label>
                  <input className={`form-control bg-light ${dataCollectionToolsError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="data_collection_tools" name="data_collection_tools" onChange={(event) => handleInputChange(event, setDataCollectionTools, setDataCollectionToolsError)}/>
                  {dataCollectionToolsError && <div className="invalid-feedback">{dataCollectionToolsError}</div>}
                </div>

                <div className="mb-4">
                  <label htmlFor="supplementary_information" className="form-label fs-5 fw-medium">Drug brochure or Supplementary information as applicable <em>(optional)</em></label>
                  <input className="form-control bg-light" type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="supplementary_information" name="supplementary_information" onChange={(event) => setSupplementaryInformation(event.target.files[0])}/>
                </div>

                <div className="mb-4">
                  <label htmlFor="permission_letter" className="form-label fs-5 fw-medium">Permission letter from head of Institution where data is to be collected <em>(For research in schools, a letter from ministry of Education is a requirement)</em></label>
                  <input className={`form-control bg-light ${permissionLetterError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="permission_letter" name="permission_letter" onChange={(event) => handleInputChange(event, setPermissionLetter, setPermissionLetterError)}/>
                  {permissionLetterError && <div className="invalid-feedback">{permissionLetterError}</div>}
                </div>

                <div className="mb-4">
                  <label htmlFor="cv" className="form-label fs-5 fw-medium">Student or Principal Investigator's CV</label>
                  <input className={`form-control bg-light ${cvError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="cv" name="cv" onChange={(event) => handleInputChange(event, setCv, setCvError)}/>
                  {cvError && <div className="invalid-feedback">{cvError}</div>}
                </div>

                {(study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials") && <>
                  <div className="mb-4">
                    <label htmlFor="specimen_consent_form" className="form-label fs-5 fw-medium">Specimen Storage and Shipment consent form. <em>(English, applicable local languages and Backtranslations)</em></label>
                    <input className={`form-control bg-light ${specimenConsentFormError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="specimen_consent_form" name="specimen_consent_form" onChange={(event) => handleInputChange(event, setSpecimenConsentForm, setSpecimenConsentFormError)}/>
                    {specimenConsentFormError && <div className="invalid-feedback">{specimenConsentFormError}</div>}
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="supervisor_details" className="form-label fs-5 fw-medium">Name, contact details and detailed curriculum vitae of academic supervisor(s)</label>
                    <input className={`form-control bg-light ${supervisorDetailsError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="supervisor_details" name="supervisor_details" onChange={(event) => handleInputChange(event, setSupervisorDetails, setSupervisorDetailsError)}/>
                    {supervisorDetailsError && <div className="invalid-feedback">{supervisorDetailsError}</div>}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="co_supervisor_confirm" className="form-label fs-5 fw-medium">For candidates registered with foreign institutions, please provide name contact details and letter from proposed local co-supervisor/adviser confirming willingness to supervise/advise</label>
                    <input className={`form-control bg-light ${coSupervisorConfirmError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="co_supervisor_confirm" name="co_supervisor_confirm" onChange={(event) => handleInputChange(event, setCoSupervisorConfirm, setCoSupervisorConfirmError)}/>
                    {coSupervisorConfirmError && <div className="invalid-feedback">{coSupervisorConfirmError}</div>}
                  </div>

                </>}      
               
                {(study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials" || study_type === "MSc" || study_type === "BSc") && <>
                  <div className="mb-4">
                    <label htmlFor="supervisor_letter" className="form-label fs-5 fw-medium">Letter of support from supervisor</label>
                    <input className={`form-control bg-light ${supervisorLetterError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="supervisor_letter" name="supervisor_letter" onChange={(event) => handleInputChange(event, setSupervisorLetter, setSupervisorLetterError)}/>
                    {supervisorLetterError && <div className="invalid-feedback">{supervisorLetterError}</div>}
                  </div>
                
                  <div className="mb-4">
                    <label htmlFor="proof_of_registration" className="form-label fs-5 fw-medium">Proof of registration with learning institution</label>
                    <input className={`form-control bg-light ${proofOfRegistrationError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="proof_of_registration" name="proof_of_registration" onChange={(event) => handleInputChange(event, setProofOfRegistration, setProofOfRegistrationError)}/>
                    {proofOfRegistrationError && <div className="invalid-feedback">{proofOfRegistrationError}</div>}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="irb_approval" className="form-label fs-5 fw-medium">University Research Ethics Committee /IRB approval</label>
                    <input className={`form-control bg-light ${irbApprovalError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="irb_approval" name="irb_approval" onChange={(event) => handleInputChange(event, setIrbApproval, setIrbApprovalError)}/>
                    {irbApprovalError && <div className="invalid-feedback">{irbApprovalError}</div>}
                  </div>
                </>}

                {(study_type === "Individual Research" || study_type === "Exempted from Ethics Review" || study_type === "Fast Track Reviews") && 
                  <div className="mb-4">
                    <label htmlFor="proof_of_funding" className="form-label fs-5 fw-medium">Proof of funding on Sponsor’s Letterhead </label>
                    <input className={`form-control bg-light ${proofOfFundingError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="proof_of_funding" name="proof_of_funding" onChange={(event) => handleInputChange(event, setProofOfFunding, setProofOfFundingError)}/>
                    {proofOfFundingError && <div className="invalid-feedback">{proofOfFundingError}</div>}
                  </div>
                }

              </div>
            </div>
            </div> 
            <div className="d-flex justify-content-between">
              <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                  Previous
              </button>
              <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                  Next
              </button>
            </div>
          </form>
               
      </>
    );
}
 
export default AttachDocuments;