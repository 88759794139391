import { useState } from "react";

const ConsentProcess = ({ consent_process, onUpdateConsent }) => {
  const rows = [];
  const [has_consent_form, setConsentForm] = useState(consent_process.has_consent_form);
  const [hasConsentFormError, setConsentFormError] = useState("");
  const [languageErrors, setLanguageErrors] = useState([]);

  const [written, setWritten] = useState(consent_process.written);
  const [verbal, setVerbal] = useState(consent_process.verbal);
  const [english, setEnglish] = useState(consent_process.english);
  const languages = consent_process.local_languages?.split(",");

  for (let i = 0; i < languages?.length; i++) {
    const languageError = languageErrors[i] || ""; // Get the error message for the corresponding language input
    rows.push(
      <div key={i}>
        <input
          type="text"
          className={`form-control mb-2 ${languageError !== "" ? "is-invalid" : ""}`}
          name={`lang[${i}]`}
          placeholder={languages[i]}
        />
        {languageError && <div className="invalid-feedback">{languageError}</div>}
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (has_consent_form.trim() === "") {
      setConsentFormError("Select option");
      return;
    }

    let consent = {id: consent_process.id, protocol: consent_process.protocol, has_consent_form};

    if (has_consent_form === "Yes") {
        const errors = []; // Array to store language input errors
        const updated_languages = [];
        for (let i = 0; i < languages.length; i++) {
            const language = e.target.elements[`lang[${i}]`].value ? e.target.elements[`lang[${i}]`].value : languages[i];
            updated_languages.push(language);

            // Perform validation on each language input
            if (language.trim() === "") {
            errors[i] = "Language is required";
            }
        }

        if (errors.length > 0) {
            setLanguageErrors(errors);
            return;
        }

        const local_languages = updated_languages.join(", ");

        consent = {id: consent_process.id, protocol: consent_process.protocol, has_consent_form, written, verbal, english, local_languages };      
    }

    onUpdateConsent(consent);
  };

  return (
      <form onSubmit={handleSubmit}>
        <div className="bg-secondary-subtle shadow p-3 mb-5 bg-white rounded">
          <table className="table">
            <tbody className="thead-light">
              <tr>
                <td>Does the research include the consent form?</td>
                <td>
                  <select
                    id="has_consent_form"
                    name="has_consent_form"
                    className={`form-select ${hasConsentFormError !== "" ? "is-invalid" : ""}`}
                    aria-label="Default select example"
                    value={has_consent_form}
                    onChange={(event) => {
                      setConsentForm(event.target.value);
                      setConsentFormError("");
                    }}
                  >
                    <option value="" disabled hidden>
                      Select ...
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {hasConsentFormError && <div className="invalid-feedback">{hasConsentFormError}</div>}
                </td>
              </tr>
            </tbody>
            {has_consent_form === "Yes" && (
              <tbody id="consent_involved">
                <tr>
                  <td>Written</td>
                  <td>
                    <input className="form-check-input" type="checkbox" name="written" checked={written} onChange={() => setWritten(!written)} />
                  </td>
                </tr>
                <tr>
                  <td>Verbal/Oral</td>
                  <td>
                    <input className="form-check-input" type="checkbox" name="verbal" checked={verbal} onChange={() => setVerbal(!verbal)} />
                  </td>
                </tr>
                <tr>
                  <td>Consent Language: English</td>
                  <td>
                    <input className="form-check-input" type="checkbox" name="english" checked={english} onChange={() => setEnglish(!english)} />
                  </td>
                </tr>
                <tr>
                  <td>Local languages</td>
                  <td>
                    {rows}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className="d-grid gap-2 col-6 mx-auto">
          <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
        </div>
      </form>
  );
};

export default ConsentProcess;
