import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import Login from './Login';
import Researcher from './Researcher';
import Reviewer from './Reviewer';
import Compliance from './Compliance';

const Home = () => {
  const { state, checkAuthenticated, handleLogin, handleLogout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const fetchAuthenticated = async () => {
      // Add a delay of 2 seconds before hiding the loading component
      setTimeout(async () => {
        await checkAuthenticated();
        setIsLoading(false);
      }, 500);
    };
    fetchAuthenticated();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogin(true);
    }, 2000); // Add a delay of 2 seconds before showing the Login component
    return () => clearTimeout(timer);
  }, [state]);

  const handleNavToggle = () => {
    // Toggle the side navigation
    const bodyElement = document.body;
    bodyElement.classList.toggle('sb-sidenav-toggled');
  
    // Store the sidebar toggle state in local storage
    const isSidebarToggled = bodyElement.classList.contains('sb-sidenav-toggled');
    localStorage.setItem('sb|sidebar-toggle', isSidebarToggled);
  };

  return (
    <div>
      {isLoading ? (
        // <div>Loading...</div>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="spinner-border" role="status" style={{ width: "6rem", height: "6rem" }}></div>
            <div className="h4 mt-3">Loading...</div>
          </div>
        </div>
      ) : (
        state.isLoggedIn ? (
          state.user.is_researcher ? <Researcher onNavToggle={handleNavToggle} user={state.user}/> : state.user.is_reviewer ? <Reviewer onNavToggle={handleNavToggle}/> : <Compliance onNavToggle={handleNavToggle}/>
        ) : (
          showLogin && <Login onLogin={handleLogin} />
        )
      )}
    </div>
  );
};
 
export default Home;
