import { useState } from "react";

const AttachDocuments = ({document, onUpdateDocs, study_type}) => {
  const [save, setSave] = useState(false);

  const [proposal_summary, setProposalSummary] = useState("");
  const [proposalSummaryError, setProposalSummaryError] = useState("");

  const [full_proposal, setFullProposal] = useState("");
  const [fullProposalError, setFullProposalError] = useState("");

  const [informed_consent_form, setInformedConsentForm] = useState("");
  const [informedConsentFormError, setInformedConsentFormError] = useState("");

  const [specimen_consent_form, setSpecimenConsentForm] = useState("");
  const [specimenConsentFormError, setSpecimenConsentFormError] = useState("");

  const [data_collection_tools, setDataCollectionTools] = useState("");
  const [dataCollectionToolsError, setDataCollectionToolsError] = useState("");

  const [supervisor_details, setSupervisorDetails] = useState("");
  const [supervisorDetailsError, setSupervisorDetailsError] = useState("");

  const [co_supervisor_confirm, setCoSupervisorConfirm] = useState("");
  const [coSupervisorConfirmError, setCoSupervisorConfirmError] = useState("");

  const [supervisor_letter, setSupervisorLetter] = useState("");
  const [supervisorLetterError, setSupervisorLetterError] = useState("");

  const [proof_of_registration, setProofOfRegistration] = useState("");
  const [proofOfRegistrationError, setProofOfRegistrationError] = useState("");

  const [irb_approval, setIrbApproval] = useState("");
  const [irbApprovalError, setIrbApprovalError] = useState("");

  const [permission_letter, setPermissionLetter] = useState("");
  const [permissionLetterError, setPermissionLetterError] = useState("");

  const [supplementary_information, setSupplementaryInformation] = useState("");

  const [cv, setCv] = useState("");
  const [cvError, setCvError] = useState("");

  const [proof_of_funding, setProofOfFunding] = useState("");
  const [proofOfFundingError, setProofOfFundingError] = useState("");

  const handleInputChange = (event, setFile, setFileError) => {
    const file = event.target.files[0];
    setFile(file);
    setFileError("");
    setSave(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let docs = {id: document.id, protocol: document.id, proposal_summary, full_proposal, informed_consent_form, data_collection_tools, permission_letter, supplementary_information, cv};
    
    if (study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials") {
      if (specimen_consent_form === null) {
        setSpecimenConsentFormError('Attach the Specimen Storage and Shipment consent form');
        return
      }

      if (supervisor_details === null) {
        setSupervisorDetailsError('Attach the academic supervisor details');
        return
      }

      if (co_supervisor_confirm === null) {
        setCoSupervisorConfirmError('Attach the local co-supervisor/adviser details');
        return
      }

      docs = {...docs, specimen_consent_form, supervisor_details, co_supervisor_confirm};
    }

    if (study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials" || study_type === "MSc" || study_type === "BSc") {
      if (supervisor_letter === null) {
        setSupervisorLetterError('Attach the letter of support from supervisor');
        return
      }

      if (proof_of_registration === null) {
        setProofOfRegistrationError('Attach the proof of registration');
        return
      }

      if (irb_approval === null) {
        setIrbApprovalError('Attach the University Research Ethics Committee /IRB approval');
        return
      }

      docs = {...docs, supervisor_letter, proof_of_registration, irb_approval};
    }

    if (study_type === "Individual Research" || study_type === "Exempted from Ethics Review" || study_type === "Fast Track Reviews") {
      if (proof_of_funding === null) {
        setProofOfFundingError('Attach the proof of funding');
        return
      }
      docs = {...docs, proof_of_funding};
    }
    
    onUpdateDocs(docs);
  }

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="shadow bg-secondary-subtle p-3 mb-5 rounded">
            <div className="form-group">
              <div className="d-grid gap-2">
                <div className="mb-4">
                  <label htmlFor="proposal_summary" className="form-label fs-5 fw-medium">Research Proposal Summary <em>(maximum 4 pages)</em></label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.proposal_summary} target='_blank' rel="noreferrer">{document.proposal_summary?.substring(document.proposal_summary?.lastIndexOf('/') + 1)} </a>
                    <br/>
                    Change: <input className={`bg-light ${proposalSummaryError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="proposal_summary" name="proposal_summary" onChange={(event) => handleInputChange(event, setProposalSummary, setProposalSummaryError)}/>
                    {proposalSummaryError && <div className="invalid-feedback">{proposalSummaryError}</div>}
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="full_proposal" className="form-label fs-5 fw-medium">Full Research Proposal</label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.full_proposal} target='_blank' rel="noreferrer">{document.full_proposal?.substring(document.full_proposal?.lastIndexOf('/') + 1)} </a>
                      <br/>
                    Change: <input className={`bg-light ${fullProposalError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="full_proposal" name="full_proposal" onChange={(event) => handleInputChange(event, setFullProposal, setFullProposalError)}/>
                    {fullProposalError && <div className="invalid-feedback">{fullProposalError}</div>}
                  </div>
                </div>
                
                <div className="mb-4">
                  <label htmlFor="informed_consent_form" className="form-label fs-5 fw-medium">Informed Consent Form</label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.informed_consent_form} target='_blank' rel="noreferrer">{document.informed_consent_form?.substring(document.informed_consent_form?.lastIndexOf('/') + 1)} </a>
                        <br/>
                    Change: <input className={`bg-light ${informedConsentFormError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="informed_consent_form" name="informed_consent_form" onChange={(event) => handleInputChange(event, setInformedConsentForm, setInformedConsentFormError)}/>
                    {informedConsentFormError && <div className="invalid-feedback">{informedConsentFormError}</div>}
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="data_collection_tools" className="form-label fs-5 fw-medium">Data collection tools: <em>English & Vernacular Versions. (Shona/ Ndebele/ appropriate language)</em></label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.data_collection_tools} target='_blank' rel="noreferrer">{document.data_collection_tools?.substring(document.data_collection_tools?.lastIndexOf('/') + 1)} </a>
                        <br/>
                    Change: <input className={`bg-light ${dataCollectionToolsError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="data_collection_tools" name="data_collection_tools" onChange={(event) => handleInputChange(event, setDataCollectionTools, setDataCollectionToolsError)}/>
                    {dataCollectionToolsError && <div className="invalid-feedback">{dataCollectionToolsError}</div>}
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="supplementary_information" className="form-label fs-5 fw-medium">Drug brochure or Supplementary information as applicable <em>(optional)</em></label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.supplementary_information} target='_blank' rel="noreferrer">{document.supplementary_information?.substring(document.supplementary_information?.lastIndexOf('/') + 1)} </a>
                        <br/>
                    Change: <input className="bg-light" type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="supplementary_information" name="supplementary_information" onChange={(event) => setSupplementaryInformation(event.target.files[0])}/>
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="permission_letter" className="form-label fs-5 fw-medium">Permission letter from head of Institution where data is to be collected <em>(For research in schools, a letter from ministry of Education is a requirement)</em></label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.permission_letter} target='_blank' rel="noreferrer">{document.permission_letter?.substring(document.permission_letter?.lastIndexOf('/') + 1)} </a>
                        <br/>
                    Change: <input className={`bg-light ${permissionLetterError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="permission_letter" name="permission_letter" onChange={(event) => handleInputChange(event, setPermissionLetter, setPermissionLetterError)}/>
                    {permissionLetterError && <div className="invalid-feedback">{permissionLetterError}</div>}
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="cv" className="form-label fs-5 fw-medium">Student or Principal Investigator's CV</label>
                  <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.cv} target='_blank' rel="noreferrer">{document.cv?.substring(document.cv?.lastIndexOf('/') + 1)} </a>
                        <br/>
                    Change: <input className={`bg-light ${cvError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="cv" name="cv" onChange={(event) => handleInputChange(event, setCv, setCvError)}/>
                    {cvError && <div className="invalid-feedback">{cvError}</div>}
                  </div>
                </div>

                {(study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials") && <>
                  <div className="mb-4">
                    <label htmlFor="specimen_consent_form" className="form-label fs-5 fw-medium">Specimen Storage and Shipment consent form. <em>(English, applicable local languages and Backtranslations)</em></label>
                    <div className="mx-4">
                      Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.specimen_consent_form} target='_blank' rel="noreferrer">{document.specimen_consent_form?.substring(document.specimen_consent_form?.lastIndexOf('/') + 1)} </a>
                          <br/>
                      Change: <input className={`bg-light ${specimenConsentFormError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="specimen_consent_form" name="specimen_consent_form" onChange={(event) => handleInputChange(event, setSpecimenConsentForm, setSpecimenConsentFormError)}/>
                      {specimenConsentFormError && <div className="invalid-feedback">{specimenConsentFormError}</div>}
                    </div>
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="supervisor_details" className="form-label fs-5 fw-medium">Name, contact details and detailed curriculum vitae of academic supervisor(s)</label>
                    <div className="mx-4">
                    Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.supervisor_details} target='_blank' rel="noreferrer">{document.supervisor_details?.substring(document.supervisor_details?.lastIndexOf('/') + 1)} </a>
                        <br/>
                    Change:  <input className={`bg-light ${supervisorDetailsError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="supervisor_details" name="supervisor_details" onChange={(event) => handleInputChange(event, setSupervisorDetails, setSupervisorDetailsError)}/>
                      {supervisorDetailsError && <div className="invalid-feedback">{supervisorDetailsError}</div>}
                      </div>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="co_supervisor_confirm" className="form-label fs-5 fw-medium">For candidates registered with foreign institutions, please provide name contact details and letter from proposed local co-supervisor/adviser confirming willingness to supervise/advise</label>
                    <div className="mx-4">
                      Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.co_supervisor_confirm} target='_blank' rel="noreferrer">{document.co_supervisor_confirm?.substring(document.co_supervisor_confirm?.lastIndexOf('/') + 1)} </a>
                          <br/>
                      Change: <input className={`bg-light ${coSupervisorConfirmError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="co_supervisor_confirm" name="co_supervisor_confirm" onChange={(event) => handleInputChange(event, setCoSupervisorConfirm, setCoSupervisorConfirmError)}/>
                      {coSupervisorConfirmError && <div className="invalid-feedback">{coSupervisorConfirmError}</div>}
                    </div>
                  </div>

                </>}      
               
                {(study_type === "PhD" || study_type === "Organisational Studies" || study_type === "Clinical Trials" || study_type === "MSc" || study_type === "BSc") && <>
                  <div className="mb-4">
                    <label htmlFor="supervisor_letter" className="form-label fs-5 fw-medium">Letter of support from supervisor</label>
                    <div className="mx-4">
                      Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.supervisor_letter} target='_blank' rel="noreferrer">{document.supervisor_letter?.substring(document.supervisor_letter?.lastIndexOf('/') + 1)} </a>
                          <br/>
                      Change: <input className={`bg-light ${supervisorLetterError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="supervisor_letter" name="supervisor_letter" onChange={(event) => handleInputChange(event, setSupervisorLetter, setSupervisorLetterError)}/>
                      {supervisorLetterError && <div className="invalid-feedback">{supervisorLetterError}</div>}
                    </div>
                  </div>
                
                  <div className="mb-4">
                    <label htmlFor="proof_of_registration" className="form-label fs-5 fw-medium">Proof of registration with learning institution</label>
                    <div className="mx-4">
                      Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.proof_of_registration} target='_blank' rel="noreferrer">{document.proof_of_registration?.substring(document.proof_of_registration?.lastIndexOf('/') + 1)} </a>
                          <br/>
                      Change: <input className={`bg-light ${proofOfRegistrationError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="proof_of_registration" name="proof_of_registration" onChange={(event) => handleInputChange(event, setProofOfRegistration, setProofOfRegistrationError)}/>
                      {proofOfRegistrationError && <div className="invalid-feedback">{proofOfRegistrationError}</div>}
                    </div>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="irb_approval" className="form-label fs-5 fw-medium">University Research Ethics Committee /IRB approval</label>
                    <div className="mx-4">
                      Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.irb_approval} target='_blank' rel="noreferrer">{document.irb_approval?.substring(document.irb_approval?.lastIndexOf('/') + 1)} </a>
                          <br/>
                      Change: <input className={`bg-light ${irbApprovalError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="irb_approval" name="irb_approval" onChange={(event) => handleInputChange(event, setIrbApproval, setIrbApprovalError)}/>
                      {irbApprovalError && <div className="invalid-feedback">{irbApprovalError}</div>}
                    </div>
                  </div>
                </>}

                {(study_type === "Individual Research" || study_type === "Exempted from Ethics Review" || study_type === "Fast Track Reviews") && 
                  <div className="mb-4">
                    <label htmlFor="proof_of_funding" className="form-label fs-5 fw-medium">Proof of funding on Sponsor’s Letterhead </label>
                      <div className="mx-4">
                        Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={document.informed_consent_form} target='_blank' rel="noreferrer">{document.informed_consent_form?.substring(document.informed_consent_form?.lastIndexOf('/') + 1)} </a>
                            <br/>
                        Change: <input className={`form-control bg-light ${proofOfFundingError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx,.xls,.xlsx" id="proof_of_funding" name="proof_of_funding" onChange={(event) => handleInputChange(event, setProofOfFunding, setProofOfFundingError)}/>
                        {proofOfFundingError && <div className="invalid-feedback">{proofOfFundingError}</div>}
                      </div>
                  </div>
                }

              </div>
            </div>
            </div> 
            {save && <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>}
        </form>
    );
}
 
export default AttachDocuments;