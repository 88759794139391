import { useState } from "react";

const ProposedResearch = ({protocol, research, onUpdateProposedResearch}) => {
    const [title, setTitle] = useState(protocol.title);
    const [titleError, setTitleError] = useState('');

    const [starting_date, setStartingDate] = useState(research.starting_date);
    const [startingDateError, setStartingDateError] = useState('');

    const [ending_date, setEndingDate] = useState(research.ending_date);
    const [endingDateError, setEndingDateError] = useState('');

    const [performance_site_in_zim, setPerformanceSiteInZim] = useState(research.performance_site_in_zim);
    const [performanceSiteInZimError, setPerformanceSiteInZimError] = useState('');

    const [performance_site_out_zim, setPerformanceSiteOutZim] = useState(research.performance_site_out_zim);
    const [performanceSiteOutZimError, setPerformanceSiteOutZimError] = useState('');

    const [number_of_study_personnel, setNumberOfStudyPersonnel] = useState(research.number_of_study_personnel);
    const [numberOfStudyPersonnelError, setNumberOfStudyPersonnelError] = useState('');

    const [currency, setCurrency] = useState(research.currency);
    const [currencyError, setCurrencyError] = useState('');

    const [budget, setBudget] = useState(research.budget);
    const [budgetError, setBudgetError] = useState('');

    const [funding_agency, setFundingAgency] = useState(research.funding_agency);
    const [fundingAgencyError, setFundingAgencyError] = useState('');

    const [funding_status, setFundingStatus] = useState(research.funding_status);
    const [fundingStatusError, setFundingStatusError] = useState('');

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
		setTitleError('');
    };

    const handleStartingDateChange = (event) => {
        setStartingDate(event.target.value);
		setStartingDateError('');
    };

    const handleEndingDateChange = (event) => {
        setEndingDate(event.target.value);
		setEndingDateError('');
    };

    const handleInZimChange = (event) => {
        setPerformanceSiteInZim(event.target.value);
		setPerformanceSiteInZimError('');
    };

    const handleOutZimChange = (event) => {
        setPerformanceSiteOutZim(event.target.value);
		setPerformanceSiteOutZimError('');
    };

    const handleStudyPersonnelChange = (event) => {
        setNumberOfStudyPersonnel(event.target.value);
		setNumberOfStudyPersonnelError('');
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);		
        setCurrencyError('');
    };

    const handleBudgetChange = (event) => {
        setBudget(event.target.value);
		setBudgetError('');
    };

    const handleFundingAgencyChange = (event) => {
        setFundingAgency(event.target.value);
		setFundingAgencyError('');
    };

    const handleFundingStatusChange = (event) => {
        setFundingStatus(event.target.value);
		setFundingStatusError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (title.trim() === '') {
            setTitleError('Enter the title');
            return;
        }

        if (starting_date.trim() === '') {
            setStartingDateError('Enter the starting date');
            return;
        }

        if (ending_date.trim() === '') {
            setEndingDateError('Enter the ending date');
            return;
        }

        if (ending_date < starting_date) {
            setEndingDateError('Ending date cannot be before starting date');
            return;
        }

        if (performance_site_in_zim.trim() === '') {
            setPerformanceSiteInZimError('Enter the performance site(s)');
            return;
        }

        if (performance_site_out_zim.trim() === '') {
            setPerformanceSiteOutZimError('Enter the performance site(s)');
            return;
        }

        if (number_of_study_personnel === 0) {
            setNumberOfStudyPersonnelError('Enter the total number of study personnel');
            return;
        }

        if (currency.trim() === "") {
            setCurrencyError('Select the currency');
            return;
        }

        if (budget === 0) {
            setBudgetError('Enter the budget');
            return;
        }

        if (funding_agency.trim() === '') {
            setFundingAgencyError('Enter the name and address of the funding agency');
            return;
        }

        if (funding_status.trim() === '') {
            setFundingStatusError('Select the funding status');
            return;
        }

        onUpdateProposedResearch({id:research.id, protocol: research.protocol, starting_date, ending_date, performance_site_in_zim, performance_site_out_zim, number_of_study_personnel, currency, budget, funding_agency, funding_status}, {title});     
    }

    return(
        <form onSubmit={handleSubmit}>
            <div className="shadow bg-secondary-subtle p-3 mb-5 rounded">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                    <tr>
                        <td>Title of proposed research</td>
                        <td>
                            <input type="text" className={`form-control ${titleError !== ''  ? 'is-invalid' : ''}`} id="title" name="title" value={title} onChange={handleTitleChange}/>
                            {titleError && <div className="invalid-feedback">{titleError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Proposed starting date</td>
                        <td>
                            <input type="date" className={`form-control date_set ${startingDateError !== ''  ? 'is-invalid' : ''}`} id="starting_date" name="starting_date" value={starting_date} onChange={handleStartingDateChange} min={starting_date}/>
                            {startingDateError && <div className="invalid-feedback">{startingDateError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Proposed ending date</td>
                        <td>
                            <input type="date" className={`form-control date_set ${endingDateError !== ''  ? 'is-invalid' : ''}`} id="ending_date" name="ending_date" value={ending_date} onChange={handleEndingDateChange} min={starting_date}/>
                            {endingDateError && <div className="invalid-feedback">{endingDateError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Performance site(s) in Zimbabwe.</td>
                        <td>
                            <input type="text" className={`form-control ${performanceSiteInZimError !== ''  ? 'is-invalid' : ''}`} id="perfomance_site_in_zim" name="perfomance_site_in_zim" value={performance_site_in_zim} onChange={handleInZimChange}/>
                            {performanceSiteInZimError && <div className="invalid-feedback">{performanceSiteInZimError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Performance site(s) outside Zimbabwe</td>
                        <td>
                            <input type="text" className={`form-control ${performanceSiteOutZimError !== ''  ? 'is-invalid' : ''}`} id="performance_site_out_zim" name="performance_site_out_zim" value={performance_site_out_zim} onChange={handleOutZimChange}/>
                            {performanceSiteOutZimError && <div className="invalid-feedback">{performanceSiteOutZimError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Total number of study personnel</td>
                        <td>
                            <input type="number" min="0" className={`form-control ${numberOfStudyPersonnelError !== ''  ? 'is-invalid' : ''}`} id="number_of_study_personnel" name="number_of_study_personnel" value={number_of_study_personnel} onChange={handleStudyPersonnelChange}/>
                            {numberOfStudyPersonnelError && <div className="invalid-feedback">{numberOfStudyPersonnelError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Budget (state currency & amount)</td>
                        <td className="row flex justify-content-between">
                            <select id="currency" name="currency" className={`form-select mb-2 ${currencyError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" value={currency} onChange={handleCurrencyChange}>
                                <option value="" disabled hidden>Select Currency ...</option>
                                <option value="€">Euro</option>
                                <option value="ZK">Kwacha</option>
                                <option value="£">Pound</option>
                                <option value="P">Pula</option>
                                <option value="R">Rand</option>
                                <option value="$">USD</option>
                                <option value="ZWL">ZWL</option>                              
                            </select>
                            {currencyError && <div className="invalid-feedback">{currencyError}</div>}
                            <input type="number" step="0.01" min="0" className={`form-control ${budgetError !== ''  ? 'is-invalid' : ''}`} id="budget" name="budget" placeholder="Enter amount" value={budget} onChange={handleBudgetChange}/>
                            {budgetError && <div className="invalid-feedback">{budgetError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Name and address of Funding agency:</td>
                        <td>
                            <input type="text" className={`form-control ${fundingAgencyError !== ''  ? 'is-invalid' : ''}`} name="funding_agency" id="funding_agency" value={funding_agency} onChange={handleFundingAgencyChange}/>
                            {fundingAgencyError && <div className="invalid-feedback">{fundingAgencyError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Status of funding :</td>
                        <td>
                            <div className={`row ${fundingStatusError !== ''  ? 'is-invalid' : ''}`}>
                                <div className="col-4 form-check">
                                    <input className="form-check-input" type="radio" name="funding_status" value="submitted"  checked={funding_status === "submitted"} onChange={handleFundingStatusChange}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">Submitted</label>
                                </div>
                                <div className="col-4 form-check">
                                    <input className="form-check-input" type="radio" name="funding_status" value="pending" checked={funding_status === "pending"} onChange={handleFundingStatusChange}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">Pending </label>
                                </div>
                                <div className="col-4 form-check">
                                    <input className="form-check-input" type="radio" name="funding_status" value="funded" checked={funding_status === "funded"} onChange={handleFundingStatusChange}/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">Funded </label>
                                </div>
                            </div>
                            {fundingStatusError && <div className="invalid-feedback">{fundingStatusError}</div>}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>         
        </form>    
    )
}

export default ProposedResearch;