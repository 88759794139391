import React from 'react';
import { BsFileWordFill, BsArrowDown } from 'react-icons/bs';
import FileInput from '../layout/FileInput';
import './researcher.css';
import termination from "../../docs/mrcz105_termination_of_study_form-2016.doc";

const StudyTermination = () => {
  return (
    <>
      <h1 className="mt-4">
        Study Termination
        <i className="bi bi-chevron-right"></i>
      </h1>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6">
          <div className="card shadow-lg p-3 mb-5 bg-white">
            <div className="text-center mt-4">
              <div className="d-flex justify-content-center text-primary fw-medium zoom-effect my-5">
                <a href={termination} className="fs-5 text-decoration-none me-1" download>
                  <BsFileWordFill />
                  Study Termination
                  <p>
                    <BsArrowDown />
                  </p>
                </a>
              </div>
              <form>
                <FileInput />
                <div className="d-flex justify-content-center my-5">
                  <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1 me-2">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyTermination;
