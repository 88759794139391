import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

const ResetPassword = () => {
    const navigate = useNavigate();

    const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);

    const [old_password, setOldPassword] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");

    const [new_password, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');

    const [confirm_password, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const handleInputChange = (event, setText, setTextError) => {
        setText(event.target.value);
        setTextError("");
    }

    // Check for authentication when the component mounts
    useEffect(() => {
        checkAuthenticated();
    }, []);

    const handleChangePassword = async (editUser, retryCount = 0) => {
        const updatedUser = { ...state.user, ...editUser };
        console.log(updatedUser);

        // Send a request with the current access token
        const response = await fetch(`https://app.mrcz.org.zw/api/accounts/change-password/${state.user.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            body: JSON.stringify(updatedUser)
        });

        if (response.status === 401 || response.status === 403) {
            if (retryCount < 3) {
                // If the server responds with a 401 (Unauthorized) or 403 (Forbidden) status code,
                // the access token has expired, so we need to refresh the token.
                setIsTokenRefreshing(true);

                // Send a request to the server to refresh the access token
                const refreshResponse = await fetch('https://app.mrcz.org.zw/api/token/refresh/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        refresh: localStorage.getItem('refresh')
                    })
                });

                if (refreshResponse.ok) {
                    // If the refresh request is successful, update the access token and
                    // retry the original request with the new access token.
                    const tokens = await refreshResponse.json();
                    localStorage.setItem('access', tokens.access);
                    setIsTokenRefreshing(false);
                    return handleChangePassword(editUser, retryCount + 1);
                }
            }

            // If the refresh request fails or the maximum retry count is reached,
            // the user needs to log in again.
            setIsTokenRefreshing(false);
            handleLogout();
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            if (response.status === 200) {
                alert("Password successfully changed!");
            } else {
                alert("Password unsuccessfully changed. Please try again.");
            }

            window.location.reload();

        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (old_password.trim() === '') {
            setOldPasswordError('Please enter current password.');
            return;
        }

        if (old_password === new_password) {
            setNewPasswordError('New password must be different from current password.');
            return;
        }

        if (!passwordRegex.test(new_password)) {
            setNewPasswordError('Please enter a password with at least 8 characters, an alphabetic character and a digit');
            return;
        }

        if (confirm_password !== new_password) {
            setConfirmPasswordError('New password does not match. Enter new password again here.');
            return;
        }

        handleChangePassword({ old_password, new_password });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="p-3 mb-5 bg-white">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Current password</td>
                            <td>
                                <input type="password" className={`form-control ${oldPasswordError !== '' ? 'is-invalid' : ''}`} id="old_password" name='old_password' value={old_password} onChange={(event) => handleInputChange(event, setOldPassword, setOldPasswordError)} />
                                {oldPasswordError && <div className="invalid-feedback">{oldPasswordError}</div>}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">New password</td>
                            <td>
                                <input type="password" className={`form-control ${newPasswordError !== '' ? 'is-invalid' : ''}`} id="new_password" name='new_password' value={new_password} onChange={(event) => handleInputChange(event, setNewPassword, setNewPasswordError)} />
                                {newPasswordError && <div className="invalid-feedback">{newPasswordError}</div>}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Re-type new password</td>
                            <td>
                                <input type="password" className={`form-control ${confirmPasswordError !== '' ? 'is-invalid' : ''}`} id="confirm_password" name='confirm_password' value={confirm_password} onChange={(event) => handleInputChange(event, setConfirmPassword, setConfirmPasswordError)} />
                                {confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="modal-footer">
                <button type="submit" className="btn btn-primary rounded-1">Change password</button>
                <button type="button" className="btn btn-secondary rounded-1" data-bs-dismiss="modal">Close</button>
            </div>
        </form>
    );
}

export default ResetPassword;