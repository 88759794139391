import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Register';

function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route path="/login" element={<Home/>} />
      <Route path="/register" element={<Register/>} />
      <Route path="/submission" element={<Home/>} />
      <Route path="/protocol/:id" element={<Home/>} />
      <Route path="/new-submissions" element={<Home/>} />
      <Route path="/assigned-submissions" element={<Home/>} />
      <Route path="/declined-submissions" element={<Home/>} />
      <Route path="/reviewed-submissions" element={<Home/>} />
      <Route path="/accepted-submissions" element={<Home/>} />
      <Route path="/rejected-submissions" element={<Home/>} />
      <Route path="/responded-submissions" element={<Home/>} />
      <Route path="/response-to-comments/:id" element={<Home/>} />
      <Route path="/study-termination" element={<Home/>} />
      <Route path="/annual-review" element={<Home/>} />
      <Route path="/adverse-event" element={<Home/>} />
      <Route path="/protocol-deviation" element={<Home/>} />
      <Route path="/study-extension" element={<Home/>} />
      <Route path="/amendment-form" element={<Home/>} />
      <Route path="/application-withdrawal" element={<Home/>} />
      <Route path="/destruction-form" element={<Home/>} />
      <Route path="/study-reopening" element={<Home/>} />
      <Route path="/death-report" element={<Home/>} />
      <Route path="/profile" element={<Home/>} />
    </Routes>
  );
}

export default Routers;
