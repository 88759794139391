import { useState } from "react";
import { Link } from "react-router-dom";
import bgimage from '../research01.png';


const Login = ({onLogin}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        const loginDetails = {email, password}
        const loginData = onLogin(loginDetails);
        console.log(loginData);
        setLoginError(loginData);
    }

    return (
        <section className="vh-100 bg-image" style={{backgroundImage: `url(${bgimage})`}}>
            <div className="mask d-flex align-items-center h-100 gradient-custom-3">
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                        <h5 className='bg-primary text-center text-white m-0 py-4 rounded-top-1'>Medical Research Council of Zimbabwe</h5>
                        <div className="position-relative">
                            <div className="card rounded-1 rounded-top-0">
                            <div className="card-body p-5">
                                <h2 className="text-center mb-5">Log into Your Account</h2>
                                {/* {loginError && (<div className="alert alert-danger" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill mx-2" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                    Invalid email or password. Please try again.
                                </div>)} */}
                                <form onSubmit={handleLogin}>
                                <div className="form-outline mb-4">
                                    <input type="email" name='emailaddress' className="form-control form-control-lg rounded-1" placeholder='Email Address' value={email} onChange={handleEmailChange} required/>
                                </div>
                                <div className="form-outline mb-4">
                                    <input type="password" name='password' className="form-control form-control-lg rounded-1" placeholder='Password' value={password} onChange={handlePasswordChange} required/>
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-primary btn-lg gradient-custom-4 text-white rounded-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                    </svg>
                                    Log In
                                    </button>
                                </div>
                                <p className="text-center text-muted mt-5 mb-0">Don't have an account? <Link to="/register" className="fw-bold text-body"><u>Register here</u></Link></p>
                                </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Login;