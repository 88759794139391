import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import Protocols from './Protocols';


const Dashboard = () => {
  const [protocols, setProtocols] = useState([])
  const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
  const location = useLocation();

  // Check for authentication when the component mounts
  useEffect(() => {
    checkAuthenticated();
    getProtocols();
  }, []);

  // Get protocol
  const getProtocol = async (id) => {
    const response = await fetch(`https://app.mrcz.org.zw/api/protocols/protocol/${id}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    });

    if (response.status === 401 || response.status === 403) {
      // If the server responds with a 401 (Unauthorized) status code,
      // the access token has expired, so we need to refresh the token.
      setIsTokenRefreshing(true);

      // Send a request to the server to refresh the access token
      const refreshResponse = await fetch('https://app.mrcz.org.zw/api/token/refresh/', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          refresh: localStorage.getItem('refresh')
        })
      });

      if (refreshResponse.ok) {
        // If the refresh request is successful, update the access token and
        // retry the original request with the new access token.
        const tokens = await refreshResponse.json();
        localStorage.setItem('access', tokens.access);
        setIsTokenRefreshing(false);
        return getProtocol(id);
      } else {
        // If the refresh request fails, the user needs to log in again.
        setIsTokenRefreshing(false);
        handleLogout();
      }
    } else {
      const data = await response.json();
      console.log(data);
      return data;
    }
  };

  // Get protocols
  const getProtocols = async () => {
    fetch('https://app.mrcz.org.zw/api/protocols/protocol/', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(response => response.json())
      .then(data => setProtocols(data))
      .catch(error => console.error(error));
  };

  // Assign Reviewer(s)
  const handleDecline = async (id, reviewer) => {
    const protocol = await getProtocol(id);
    const updated_protocol = { ...protocol, ...reviewer }
    console.log(updated_protocol);
    // Send a request with the current access token
    fetch(`https://app.mrcz.org.zw/api/protocols/protocol/${id}/`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_protocol)
    })
      .then(response => response.json())
      .then(data => {
        getProtocols();
        alert(`Protocol ${protocol.number} successfully declined.`);
      })
      .catch(error => console.log(error))
  };



  return (
    <>
      <h1 className="mt-4">Dashboard <i className="bi bi-chevron-right"></i></h1>
      <div className="row">
        <div className="col-xl-6 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-secondary h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-secondary fw-medium">Assigned Submissions <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "assigned").length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-primary h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-primary fw-medium">Reviewed Submissions <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "reviewed" || protocol.status === "partially reviewed").length}</p></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (() => {
          switch (location.pathname) {
            case '/':
              return protocols.length > 0 ? <Protocols protocols={protocols} onDecline={handleDecline} user={state.user} /> : <h4>No submissions to display</h4>;
            case '/assigned-submissions':
              return protocols?.filter(protocol => protocol.status === "assigned").length > 0 ? <Protocols protocols={protocols.filter(protocol => protocol.status === "assigned")} title="Assigned" /> : <h4>No assigned submissions to display</h4>;
            case '/reviewed-submissions':
              return protocols?.filter(protocol => protocol.status === "reviewed" || protocol.status === "partially reviewed").length > 0 ? <Protocols protocols={protocols.filter(protocol => protocol.status === "reviewed" || protocol.status === "partially reviewed")} title="Reviewed" user={state.user} /> : <h4>No reviewed submissions to display</h4>;
            default:
              return protocols.length > 0 ? <Protocols protocols={protocols} onDecline={handleDecline} user={state.user} /> : <h4>No submissions to display</h4>;
          }
        })()
      }
    </>
  );
}

export default Dashboard;