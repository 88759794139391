import { useState } from "react";

const CollaboratingInstitutions = ({collaborating_institutions, onUpdateCollabs}) => {
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Gather data from input fields
    const formData = [];
    const errors = {};

    for (let i = 0; i < collaborating_institutions.length; i++) {
      const name = event.target.elements[`collabo_name[${i}]`].value ? event.target.elements[`collabo_name[${i}]`].value : collaborating_institutions[i].name;
      const focal_person = event.target.elements[`collabo_person[${i}]`].value ? event.target.elements[`collabo_person[${i}]`].value : collaborating_institutions[i].focal_person;
      const telephone = event.target.elements[`collabo_tel[${i}]`].value ? event.target.elements[`collabo_tel[${i}]`].value : collaborating_institutions[i].telephone;
      const email = event.target.elements[`collabo_email[${i}]`].value ? event.target.elements[`collabo_email[${i}]`].value : collaborating_institutions[i].email;

      // Validate input fields
      if (name.trim() === "") {
        errors[`collabo_name[${i}]`] = "Enter the institution name";
      }

      if (focal_person.trim() === "") {
        errors[`collabo_person[${i}]`] = "Enter the contact person";
      }

      if (telephone.trim() === "") {
        errors[`collabo_tel[${i}]`] = "Enter the telephone number";
      } else if (!/^\+\d{1,4}\d{10}$/.test(telephone)) {
        errors[`collabo_tel[${i}]`] = 'Enter a valid telephone number';
      }

      if (email.trim() === "") {
        errors[`collabo_email[${i}]`] = "Enter the email";
      } else if (!isValidEmail(email)) {
        errors[`collabo_email[${i}]`] = "Enter a valid email address";
      }

      formData.push({id: collaborating_institutions[i].id, protocol: collaborating_institutions[i].protocol, name, focal_person, telephone, email });
    }

    if (Object.keys(errors).length === 0) {
      onUpdateCollabs(formData);
    } else {
      setFormErrors(errors);
    }
  };

  const isValidEmail = (email) => {
    // You can implement your own email validation logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const renderInputClassName = (fieldName) => {
    return formErrors[fieldName] ? "form-control is-invalid" : "form-control";
  };

  const renderErrorMessage = (fieldName) => {
    return formErrors[fieldName] && (
      <div className="invalid-feedback">{formErrors[fieldName]}</div>
    );
  };

  const rows = [];

  for (let i = 0; i < collaborating_institutions.length; i++) {
    rows.push(
      <tr key={i}>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_name[${i}]`)}
            name={`collabo_name[${i}]`}
            placeholder={collaborating_institutions[i].name}

          />
          {renderErrorMessage(`collabo_name[${i}]`)}
        </td>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_person[${i}]`)}
            name={`collabo_person[${i}]`}
            placeholder={collaborating_institutions[i].focal_person}

          />
          {renderErrorMessage(`collabo_person[${i}]`)}
        </td>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_tel[${i}]`)}
            name={`collabo_tel[${i}]`}
            placeholder={collaborating_institutions[i].telephone}

          />
          {renderErrorMessage(`collabo_tel[${i}]`)}
        </td>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_email[${i}]`)}
            name={`collabo_email[${i}]`}
            placeholder={collaborating_institutions[i].email}

          />
          {renderErrorMessage(`collabo_email[${i}]`)}
        </td>
      </tr>
    );
  }

  return (
      <form onSubmit={handleSubmit}>
        <div className="bg-secondary-subtle shadow p-3 mb-5 rounded">
          <table className="table">
            <thead className="thead-light" id="collabo">
              <tr>
                <th scope="col">Institution</th>
                <th scope="col">Contact/ Focal Person (Name)</th>
                <th>Telephone #</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className="d-grid gap-2 col-6 mx-auto">
          <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
        </div>
      </form>
  );
};

export default CollaboratingInstitutions;
