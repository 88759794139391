import { useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';


const SideBar = () => {
    const { state, checkAuthenticated, handleLogout} = useContext(AuthContext);
  
    useEffect(() => {
        checkAuthenticated();
    }, []); 

    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <div className="m-2 mt-4 p-2 bg-secondary rounded-pill">
                            <Link to="/profile" className="py-2 px-2 text-decoration-none d-flex align-items-center">
                                <div className="col-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-person text-white" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                    </svg>
                                </div>
                                <div className="col">
                                    <span className="font-weight-bolder text-white">{state.user.first_name} {state.user.last_name}</span>
                                </div>
                            </Link>
                        </div>
                        <Link className="nav-link" to="/">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Dashboard
                        </Link>
                        <div className="sb-sidenav-menu-heading">Submissions</div>
                        <Link className="nav-link" to="/submission">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            New Study Application
                        </Link>
                        <Link className="nav-link" to="/study-termination">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Study Termination
                        </Link>
                        <Link className="nav-link" to="/annual-review">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Annual Review
                        </Link>
                        <Link className="nav-link" to="/adverse-event">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Adverse Event
                        </Link>
                        <Link className="nav-link" to="/protocol-deviation">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Protocol Deviation
                        </Link>
                        <Link className="nav-link" to="/study-extension">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Study Extension
                        </Link>
                        <Link className="nav-link" to="/amendment-form">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Amendment Form
                        </Link>
                        <Link className="nav-link" to="/application-withdrawal">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Application Withdrawal
                        </Link>
                        <Link className="nav-link" to="/destruction-form">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Destruction Form
                        </Link>
                        <Link className="nav-link" to="/study-reopening">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Study Reopening
                        </Link>
                        <Link className="nav-link" to="/death-report">
                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                            Death Report Form
                        </Link>
                        <div className="sb-sidenav-menu-heading">Profile</div>
                        <Link className="nav-link" to="/profile">
                            <div className="sb-nav-link-icon"><i className="fas fa-user"></i></div>
                            Researcher
                        </Link>
                    </div>
                    <div className="sb-sidenav-footer bg-danger">
                        <button type="button" className="text-light fw-semibold text-decoration-none btn btn-danger" onClick={handleLogout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                        </svg>
                            Logout
                        </button>
                    </div>
                </div>
                
            </nav>
        </div>
      );
}
 
export default SideBar;