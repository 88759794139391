import { useState } from "react";


const ResearchCoordinator = ({coordinator, onUpdateResearchCoordinator}) => {
    const [name, setName] = useState(coordinator.name);
    const [nameError, setNameError] = useState("");

    const [address, setAddress] = useState(coordinator.address);
    const [addressError, setAddressError] = useState("");

    const [email, setEmail] = useState(coordinator.email);
    const [emailError, setEmailError] = useState("");

    const [telephone, setTelephone] = useState(coordinator.telephone);
    const [telephoneError, setTelephoneError] = useState("");

    const [mobile, setMobile] = useState(coordinator.mobile);
    const [mobileError, setMobileError] = useState("");

    const [site, setSite] = useState(coordinator.site);
    const [siteError, setSiteError] = useState("");
    
    const handleInputChange = (event, setInput, setInputError) => {
        setInput(event.target.value);
        setInputError("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(name.trim() === ""){
            setNameError("Enter the name");
            return;
        }

        if(address.trim() === ""){
            setAddressError("Enter the postal address");
            return;
        }

        if(email.trim() === ""){
            setEmailError("Enter the email address");
            return;
        }

        if(telephone.trim() === ""){
            setTelephoneError("Enter the telephone number");
            return;
        }

        if (!/^\+\d{1,4}\d{10}$/.test(telephone)) {
            setTelephoneError('Please enter valid telephone number');
            return;
        }

        if(mobile.trim() === ""){
            setMobileError("Enter the mobile number");
            return;
        }

        if (!/^\+\d{1,4}\d{10}$/.test(mobile)) {
            setMobileError('Please enter valid mobile number');
            return;
        }

        if(site.trim() === ""){
            setSiteError("Enter the site");
            return;
        }

        const research_coordinator = {id: coordinator.id, protocol: coordinator.protocol, name, address, email, telephone, mobile, site};

        onUpdateResearchCoordinator(research_coordinator);      
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-secondary-subtle shadow p-3 mb-5 rounded">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                        <tr>
                        <td>Name</td>
                        <td>
                            <input type="text" className={`form-control ${nameError !== ''  ? 'is-invalid' : ''}`} id="name" name='name' value={name} onChange={(event) => handleInputChange(event, setName, setNameError)}/>
                            {nameError && <div className="invalid-feedback">{nameError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Postal address</td>
                        <td>
                            <input type="text" className={`form-control ${addressError !== ''  ? 'is-invalid' : ''}`} id="address" name='address' value={address} onChange={(event) => handleInputChange(event, setAddress, setAddressError)}/>
                            {addressError && <div className="invalid-feedback">{addressError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>E-mail Address</td>
                        <td>
                            <input type="email" className={`form-control ${emailError !== ''  ? 'is-invalid' : ''}`} id="email" name='email' value={email} onChange={(event) => handleInputChange(event, setEmail, setEmailError)}/>
                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                        </td>
                        </tr>               
                        <tr>
                        <td>Telephone Number</td>
                        <td>
                            <input type="text" className={`form-control ${telephoneError !== ''  ? 'is-invalid' : ''}`} id="telephone" name='telephone' value={telephone} onChange={(event) => handleInputChange(event, setTelephone, setTelephoneError)}/>
                            {telephoneError && <div className="invalid-feedback">{telephoneError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Mobile Number</td>
                        <td>
                            <input type="text" className={`form-control ${mobileError !== ''  ? 'is-invalid' : ''}`} id="mobile" name='mobile' value={mobile} onChange={(event) => handleInputChange(event, setMobile, setMobileError)}/>
                            {mobileError && <div className="invalid-feedback">{mobileError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Site where Coordinator is stationed</td>
                        <td>
                            <input type="text" className={`form-control ${siteError !== ''  ? 'is-invalid' : ''}`} id="site" name='site' value={site} onChange={(event) => handleInputChange(event, setSite, setSiteError)}/>
                            {siteError && <div className="invalid-feedback">{siteError}</div>}
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>
        </form>
    );
}
 
export default ResearchCoordinator;