import { useState } from "react";


const Population = ({onGetPopulation, onGetStudy, onPrev}) => {
    // Population data
    const [males, setMales] = useState(false);
    const [females, setFemales] = useState(false);
    const [adolescents, setAdolescents] = useState(false);
    const [children, setChildren] = useState(false);
    const [pregnant_women, setPregnantWomen] = useState(false);
    const [foetuses, setFoetuses] = useState(false);
    const [elderly, setElderly] = useState(false);
    const [prisoners, setPrisoners] = useState(false);
    const [cognitively_impaired, setCognitivelyImpaired] = useState(false);
    const [hospital_inpatients, setHospitalInpatients] = useState(false);
    const [sexual_minorities, setSexualMinorities] = useState(false);
    const [sex_workers, setSexWorkers] = useState(false);

    // Study type data
    const [survey, setSurvey] = useState(false);
    const [secondary, setSecondary] = useState(false);
    const [observational_clinical_trials, setObservationalClinicalTrials] = useState(false);
    const [clinical_trial, setClinicalTrial] = useState(false);
    const [lab_research, setLabResearch] = useState(false);
    const [record_review, setRecordReview] = useState(false);
    const [operations_research, setOperationsResearch] = useState(false);
    const [qualitative, setQualitative] = useState(false);
    const [device_study, setDeviceStudy] = useState(false);
    const [other, setOther] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        const population = {males, females, adolescents, children, pregnant_women, foetuses, elderly, prisoners, cognitively_impaired, hospital_inpatients, sexual_minorities, sex_workers};
        const study = other.trim() === "" ? {survey, secondary, observational_clinical_trials, clinical_trial, lab_research, record_review, operations_research, qualitative, device_study} : {survey, secondary, observational_clinical_trials, clinical_trial, lab_research, record_review, operations_research, qualitative, device_study, other};

        onGetPopulation(population);
        onGetStudy(study);
    }

    return(
        <>
            <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-6">
                    <h1 className="my-4">Population (*)</h1>
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                    <span>Population: Proposed inclusion criteria</span>
                    <em> (Check all that applies)</em>
                    <table className="table">
                    <thead className="thead-light bg-light">
                        <tr>
                        <th>Category</th>
                        <th>Check</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Males</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="males" checked={males} onChange={(e) => setMales(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Females</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="females" checked={females} onChange={(e) => setFemales(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Adolescents (12 – 17 years)</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="adolescents" checked={adolescents} onChange={(e) => setAdolescents(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Children (Under 12 years of age)</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="children" checked={children} onChange={(e) => setChildren(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Pregnant women</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="pregnant_women" checked={pregnant_women} onChange={(e) => setPregnantWomen(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Foetuses</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="foetuses" checked={foetuses} onChange={(e) => setFoetuses(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Elderly (over 65 years)</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="elderly" checked={elderly} onChange={(e) => setElderly(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Prisoners</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="prisoners" checked={prisoners} onChange={(e) => setPrisoners(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Cognitively impaired</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="cognitively_impaired" checked={cognitively_impaired} onChange={(e) => setCognitivelyImpaired(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Hospital inpatients</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="hospital_inpatients" checked={hospital_inpatients} onChange={(e) => setHospitalInpatients(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Sexual Minorities</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="sexual_minorities" checked={sexual_minorities} onChange={(e) => setSexualMinorities(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Sex workers</td>
                        <td align="center">
                            <input className="form-check-input" type="checkbox" name="sex_workers" checked={sex_workers} onChange={(e) => setSexWorkers(e.target.checked)}/>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    </div>
                    </div>

                    <div className="col-6">
                    <h1 className="my-4">Type of Study (*)</h1>
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                    <em>(Check all that applies)</em>
                    <table className="table">
                    <thead className="thead-light">
                        {/* <!--<tr>
                        <th>Category</th>
                        <th>Check</th>
                        </tr>--> */}
                    </thead>
                    <tbody>
                    <tr>
                        <td>Survey</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="survey" checked={survey} onChange={(e) => setSurvey(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Secondary data</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="secondary" checked={secondary} onChange={(e) => setSecondary(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Observational Clinical Trials</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="observational_clinical_trials" checked={observational_clinical_trials} onChange={(e) => setObservationalClinicalTrials(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Clinical trial</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="clinical_trial" checked={clinical_trial} onChange={(e) => setClinicalTrial(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Lab Based/Biomedical Research</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="lab_research" checked={lab_research} onChange={(e) => setLabResearch(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Record review</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="record_review" checked={record_review} onChange={(e) => setRecordReview(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Operations Research</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="operations_research" checked={operations_research} onChange={(e) => setOperationsResearch(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Qualitative / Social / Behavioural</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="qualitative" checked={qualitative} onChange={(e) => setQualitative(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Device Study</td>
                        <td align="center">
                        <input className="form-check-input" type="checkbox" name="device_study" value="device_study" checked={device_study} onChange={(e) => setDeviceStudy(e.target.checked)}/>
                        </td>
                        </tr>
                        <tr>
                        <td>Other (specify)</td>
                        <td>
                        <input className="form-control" type="text" name="other" value={other} onChange={(e) => setOther(e.target.value)}/>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                        Previous
                    </button>
                    <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                        Next
                    </button>
                </div>
            </form>
        </>
    )
}

export default Population;