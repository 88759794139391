import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import Protocols from './Protocols';

const Dashboard = () => {
    const [protocols, setProtocols] = useState([])
    const { checkAuthenticated, handleLogout } = useContext(AuthContext);

    // Check for authentication when the component mounts
    useEffect(() => {
        checkAuthenticated();
        getProtocols();
    }, []);

    const getProtocols = async () => {
        fetch('https://app.mrcz.org.zw/api/protocols/protocols/', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
        })
            .then(response => response.json())
            .then(data => setProtocols(data))
            .catch(error => console.error(error));
    };


    return (
        <>
            <h1 className="mt-4">Dashboard <i className="bi bi-chevron-right"></i></h1>
            <div className="row">
                <div className="col-xl-3 col-md-6">
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                        <div className="row">
                            <div className="col-2 d-flex align-items-center justify-content-center">
                                <button type="button" className="btn btn-outline-dark h-100 w-300 d-flex align-items-center justify-content-center">
                                    <i className="fab fa-gitter font-20px"></i>
                                </button>
                            </div>
                            <div className="col-10 d-flex align-items-center">
                                <span className="text-dark fw-medium">Total Submissions <p className="font-weight-bold">{protocols?.length}</p></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                        <div className="row">
                            <div className="col-2 d-flex align-items-center justify-content-center">
                                <button type="button" className="btn btn-outline-warning h-100 w-300 d-flex align-items-center justify-content-center">
                                    <i className="fab fa-gitter font-20px"></i>
                                </button>
                            </div>
                            <div className="col-10 d-flex align-items-center">
                                <span className="text-warning fw-medium">Pending<p className="font-weight-bold">{protocols?.filter(protocol => protocol.status === "pending").length}</p></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                        <div className="row">
                            <div className="col-2 d-flex align-items-center justify-content-center">
                                <button type="button" className="btn btn-outline-success h-100 w-300 d-flex align-items-center justify-content-center">
                                    <i className="fab fa-gitter font-20px"></i>
                                </button>
                            </div>
                            <div className="col-10 d-flex align-items-center">
                                <span className="text-success fw-medium">Accepted<p className="font-weight-bold">{protocols?.filter(protocol => protocol.status === "accepted").length}</p></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="card shadow p-3 mb-5 bg-white rounded">
                        <div className="row">
                            <div className="col-2 d-flex align-items-center justify-content-center">
                                <button type="button" className="btn btn-outline-danger h-100 w-300 d-flex align-items-center justify-content-center">
                                    <i className="fab fa-gitter font-20px"></i>
                                </button>
                            </div>
                            <div className="col-10 d-flex align-items-center">
                                <span className="text-danger fw-medium">Rejected<p className="font-weight-bold">{protocols?.filter(protocol => protocol.status === "rejected").length}</p></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {protocols.length > 0 ? <Protocols protocols={protocols} /> : <h4>No submissions to display</h4>}
        </>
    );
}

export default Dashboard;