import { useState } from "react";

const ResearchTeam = ({onGetStudyType, onGetCoInvestigators, onGetResearchTeam}) => {
    const [step, setStep] = useState(1);

    const [study_type, setStudyType] = useState("")
    const [studyTypeError, setStudyTypeError] = useState("")

    const [principal_investigator, setPrincipalInvestigator] = useState("");
    const [principalInvestigatorError, setPrincipalInvestigatorError] = useState('');

    const [nationality, setNationality] = useState("");
    const [nationalityError, setNationalityError] = useState('');

    const [existing_qualifications, setExistingQualifications] = useState("");
    const [existingQualificationsError, setExistingQualificationsError] = useState('');

    const [academic_title, setAcademicTitle] = useState("");
    const [academicTitleError, setAcademicTitleError] = useState('');

    const [institution, setInstitution] = useState("");
    const [institutionError, setInstitutionError] = useState('');

    const [postal_address, setPostalAddress] = useState("");
    const [postalAddressError, setPostalAddressError] = useState('');

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');

    const [telephone, setTelephone] = useState("");
    const [telephoneError, setTelephoneError] = useState('');

    const [up_for_award, setUpForAward] = useState("");
    const [upForAwardError, setUpForAwardError] = useState("");
    
    const [degree_type, setDegreeType] = useState("");
    const [degreeTypeError, setDegreeTypeError] = useState('');

    const [student_name, setStudentName] = useState("");
    const [studentNameError, setStudentNameError] = useState('');

    const [student_varisty, setStudentVarsity] = useState("");
    const [studentVarsityError, setStudentVarsityError] = useState('');

    const [student_info, setStudentInfo] = useState("");
    const [studentInfoError, setStudentInfoError] = useState('');

    const [coInvestigatorErrors, setCoInvestigatorErrors] = useState({});

    const handleAdd = () => {
        setStep(step + 1);
      };
    
    const handleRemove = () => {
        step > 0 && setStep(step - 1);
    }

    const handleStudyChange = (event) => {
        setStudyType(event.target.value);
        setStudyTypeError('');
    };

    const handlePrincipleChange = (event) => {
        setPrincipalInvestigator(event.target.value);
		setPrincipalInvestigatorError('');
    };

    const handleNationalityChange = (event) => {
        setNationality(event.target.value);
		setNationalityError('');
    };

    const handleQualificationsChange = (event) => {
        setExistingQualifications(event.target.value);
		setExistingQualificationsError('');
    };

    const handleAcademicChange = (event) => {
        setAcademicTitle(event.target.value);
		setAcademicTitleError('');
    };

    const handleInstitutionChange = (event) => {
        setInstitution(event.target.value);
		setInstitutionError('');
    };

    const handleAddressChange = (event) => {
        setPostalAddress(event.target.value);
		setPostalAddressError('');
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
		setEmailError('');
    };

    const handleTelephoneChange = (event) => {
        setTelephone(event.target.value);
		setTelephoneError('');
    };

    const handleAwardChange = (event) => {
        setUpForAward(event.target.value);
		setUpForAwardError('');
    };

    const handleDegreeChange = (event) => {
        setDegreeType(event.target.value);
		setDegreeTypeError('');
    };

    const handleStudentChange = (event) => {
        setStudentName(event.target.value);
		setStudentNameError('');
    };

    const handleVarsityChange = (event) => {
        setStudentVarsity(event.target.value);
		setStudentVarsityError('');
    };

    const handleInfoChange = (event) => {
        setStudentInfo(event.target.value);
		setStudentInfoError('');
    };    
    
    const onSubmit = (event) => {
        event.preventDefault();

        if (study_type.trim() === '') {
            setStudyTypeError('Select the study type');
            return;
        }

        if (principal_investigator.trim() === '') {
            setPrincipalInvestigatorError('Enter the Principal Investigator');
            return;
        }

        if (nationality.trim() === '') {
            setNationalityError('Enter the nationality');
            return;
        }

        if (existing_qualifications.trim() === '') {
            setExistingQualificationsError('Enter existing qualifications');
            return;
        }

        if (academic_title.trim() === '') {
            setAcademicTitleError('Enter the academic title');
            return;
        }

        if (institution.trim() === '') {
            setInstitutionError('Enter the Institution & Department');
            return;
        }
        
        if (postal_address.trim() === '') {
            setPostalAddressError('Enter the postal address');
            return;
        }

        if (email.trim() === '') {
            setEmailError('Enter the email');
            return;
        }

        if (telephone.trim() === '') {
            setTelephoneError('Enter the telephone number');
            return;
        }

        if (!/^\+\d{1,4}\d{10}$/.test(telephone)) {
            setTelephoneError('Please enter valid telephone number');
            return;
        }

        if (up_for_award.trim() === '') {
            setUpForAwardError("Select option");
            return;
        }

        if (up_for_award === "Yes"){

            if (degree_type.trim() === '') {
                setDegreeType("Enter the degree type");
                return;
            }
        
            if (student_name.trim() === '') {
                setStudentNameError("Enter the student's name");
                return;
            }

            if (student_varisty.trim() === '') {
                setStudentVarsityError("Enter the student's university/insitution");
                return;
            }
        }

        if (student_info.trim() === '' && (study_type === "PhD" || study_type === "MSc" || study_type === "BSc" || up_for_award === "Yes")) {
            setStudentInfoError("Enter the student's # and year of study");
            return;
        }

        const co_investigators = [];
        const errors = {};

        for (let i = 0; i < step; i++) {
          const name = event.target.elements[`name[${i}]`].value;
          const qualifications = event.target.elements[`qualifications[${i}]`].value;
          const department = event.target.elements[`department[${i}]`].value;
    
          if (name.trim() === "") {
            errors[`name[${i}]`] = "Enter the name";
          }

          if (qualifications.trim() === "") {
            errors[`qualifications[${i}]`] = "Enter the qualifications";
          }

          if (department.trim() === "") {
            errors[`department[${i}]`] = "Enter the department";
          }
          
          co_investigators.push({name, qualifications, department});
        }

        if (Object.keys(errors).length !== 0) {
            setCoInvestigatorErrors(errors);
            return;
        }

        const research_team = {principal_investigator, nationality, existing_qualifications, academic_title, institution, postal_address, email, telephone, up_for_award, degree_type, student_name, student_varisty, student_info};
        
        onGetStudyType(study_type);
        onGetCoInvestigators(co_investigators);
        onGetResearchTeam(research_team);      
    }

    const renderInputClassName = (fieldName) => {
        return coInvestigatorErrors[fieldName] ? "form-control is-invalid" : "form-control";
      };
    
    const renderErrorMessage = (fieldName) => {
        return coInvestigatorErrors[fieldName] && (
            <div className="invalid-feedback">{coInvestigatorErrors[fieldName]}</div>
        );
    };

    const rows = [];

    for (let i = 0; i < step; i++) {
        rows.push(
            <tr key={i}>
                <td>
                <input
                    type="text"
                    className={renderInputClassName(`name[${i}]`)}
                    name={`name[${i}]`}

                />
                {renderErrorMessage(`name[${i}]`)}
                </td>
                <td>
                <input
                    type="text"
                    className={renderInputClassName(`qualifications[${i}]`)}
                    name={`qualifications[${i}]`}

                />
                {renderErrorMessage(`qualifications[${i}]`)}
                </td>
                <td>
                <input
                    type="text"
                    className={renderInputClassName(`department[${i}]`)}
                    name={`department[${i}]`}

                />
                {renderErrorMessage(`department[${i}]`)}
                </td>
            </tr>
        );
    }

    return (
        <>
            <h1 className="my-4">Details of Research Team</h1>
            <form onSubmit={onSubmit} method="post">
            <div className="card shadow p-3 mb-5 bg-white rounded">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                        <tr className="my-3">
                            <td></td>
                            <td><b>Select study type</b></td>
                            <td>
                                <select id='study_type' className={`form-select ${studyTypeError !== ''  ? 'is-invalid' : ''}`} name='study_type' aria-label="Default select example" value={study_type} onChange={handleStudyChange}>
                                    <option value="" disabled hidden>Select ...</option>
                                    <option value="PhD">PhD</option>
                                    <option value="MSc">MSc</option>
                                    <option value="BSc">BSc</option>
                                    <option value="Exempted from Ethics Review">Exempted from Ethics Review</option>
                                    <option value="Fast Track Reviews">Fast Track Reviews</option>
                                    <option value="Organisational Studies">Organisational Studies</option>
                                    <option value="Individual Research">Individual Research</option>
                                    <option value="Clinical Trials">Clinical Trials</option>
                                </select>
                                {studyTypeError && <div className="invalid-feedback">{studyTypeError}</div>}
                            </td>
                        </tr>
                        <tr>
                        <td>1</td>
                        <td>Name of Principal Investigator (P.I)</td>
                        <td>
                            <input type="text" className={`form-control ${principalInvestigatorError !== ''  ? 'is-invalid' : ''}`} id="principal_investigator" name='principal_investigator' value={principal_investigator} onChange={handlePrincipleChange}/>
                            {principalInvestigatorError && <div className="invalid-feedback">{principalInvestigatorError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>2</td>
                            <td>Nationality of P.I</td>
                            <td>
                            <input type="text" className={`form-control ${nationalityError !== ''  ? 'is-invalid' : ''}`} id="nationality" name='nationality' value={nationality} onChange={handleNationalityChange}/>
                            {nationalityError && <div className="invalid-feedback">{nationalityError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>3</td>
                        <td>Existing Qualifications</td>
                        <td>
                            <input type="text" className={`form-control ${existingQualificationsError !== ''  ? 'is-invalid' : ''}`} id="existing_qualifications" name='existing_qualifications' value={existing_qualifications} onChange={handleQualificationsChange}/>
                            {existingQualificationsError && <div className="invalid-feedback">{existingQualificationsError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>4</td>
                        <td>Academic Title</td>
                        <td>
                            <input type="text" className={`form-control ${academicTitleError !== ''  ? 'is-invalid' : ''}`} id="academic_title" name='academic_title' value={academic_title} onChange={handleAcademicChange}/>
                            {academicTitleError && <div className="invalid-feedback">{academicTitleError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>5</td>
                        <td>Institution & Dept.</td>
                        <td>
                            <input type="text" className={`form-control ${institutionError !== ''  ? 'is-invalid' : ''}`} id="institution" name='institution' value={institution} onChange={handleInstitutionChange}/>
                            {institutionError && <div className="invalid-feedback">{institutionError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>6</td>
                        <td>Postal address</td>
                        <td>
                            <input type="text" className={`form-control ${postalAddressError !== ''  ? 'is-invalid' : ''}`} id="postal_address" name='postal_address' value={postal_address} onChange={handleAddressChange}/>
                            {postalAddressError && <div className="invalid-feedback">{postalAddressError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>7</td>
                        <td>Email</td>
                        <td>
                            <input type="email" className={`form-control ${emailError !== ''  ? 'is-invalid' : ''}`} id="email" name='email' value={email} onChange={handleEmailChange}/>
                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>8</td>
                        <td>Telephone No.</td>
                        <td>
                            <input type="text" className={`form-control ${telephoneError !== ''  ? 'is-invalid' : ''}`} id="telephone" name='telephone' value={telephone} onChange={handleTelephoneChange}/>
                            {telephoneError && <div className="invalid-feedback">{telephoneError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>9</td>
                        <td>
                            Is this research expected to lead to the award of a higher degree for the PI or any other research team member?
                        </td>
                        <td>
                            <select name="up_for_award" id="up_for_award" value={up_for_award} className={`form-select ${upForAwardError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" onChange={handleAwardChange}>
                                <option value="" disabled hidden>Select ...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {upForAwardError && <div className="invalid-feedback">{upForAwardError}</div>}
                        </td>
                        </tr>
                        {up_for_award === "Yes" && <><tr id="degree_var">
                            <td>10</td>
                            <td>
                                Degree Type
                            </td>
                            <td>
                                <select id="degree_type" name='degree_type' className={`form-select ${degreeTypeError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" value={degree_type} onChange={handleDegreeChange}>
                                    <option value="" disabled hidden>Select Degree ...</option>
                                    <option value="Undergraduate">Undergraduate</option>
                                    <option value="MSc/MA/MMed/MPhil">MSc/MA/MMed/MPhil</option>
                                    <option value="Other">Other</option>
                                </select>
                                {degreeTypeError && <div className="invalid-feedback">{degreeTypeError}</div>}
                            </td>
                        </tr>
                        <tr id='name_11'>
                            <td>11</td>
                            <td>Name of student if not the PI</td>
                            <td>
                                <input type="text" className={`form-control ${studentNameError !== ''  ? 'is-invalid' : ''}`} id="student_name" name='student_name' value={student_name} onChange={handleStudentChange}/>
                                {studentNameError && <div className="invalid-feedback">{studentNameError}</div>}
                            </td>
                        </tr>
                        <tr id='name_12'>
                            <td>12</td>
                            <td>University/Institution where student is registered</td>
                            <td>
                                <input type="text" className={`form-control ${studentVarsityError !== ''  ? 'is-invalid' : ''}`} id="student_varsity" name='student_varsity' value={student_varisty} onChange={handleVarsityChange}/>
                                {studentVarsityError && <div className="invalid-feedback">{studentVarsityError}</div>}
                            </td>
                        </tr></>}
                        {(study_type === "PhD" || study_type === "MSc" || study_type === "BSc" || up_for_award === "Yes") && <tr id='name_13'>
                            <td>13</td>
                            <td>Student # and Year of Study</td>
                            <td>
                                <input type="text" className={`form-control ${studentInfoError !== ''  ? 'is-invalid' : ''}`} id="student_info" name='student_info' value={student_info} onChange={handleInfoChange}/>
                                {studentInfoError && <div className="invalid-feedback">{studentInfoError}</div>}
                            </td>
                        </tr>}
                        <tr>
                            <th scope="col">Co-investigators Names</th>
                            <th scope="col">Qualifications</th>
                            <th scope="col">Institution/Department</th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end my-3">
                        <button
                        className="btn btn-outline-primary me-md-2 rounded-1"
                        type="button"
                        onClick={handleAdd}
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                        >
                            <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                            />
                        </svg>
                        </button>
                        {step > 1 && (
                        <button
                            className="btn btn-outline-primary rounded-1"
                            type="button"
                            onClick={handleRemove}
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-dash-lg"
                            viewBox="0 0 16 16"
                            >
                            <path
                                fillRule="evenodd"
                                d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                            />
                            </svg>
                        </button>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-row-reverse">
                    <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                        Next
                    </button>
                </div>
            </form>
        </>
    )
}

export default ResearchTeam;