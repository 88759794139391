import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PendingProtocol = ({protocol, reviewers, onAssignReviewers}) => {
    const [reviewer, setReviewer] = useState(undefined);
    const [alt_reviewer, setAltReviewer] = useState(undefined);
    const navigate = useNavigate();

    const handleMoreClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const status = "assigned";
        var assigned_reviewers;

        if (protocol.number.search("A") === 5) {
            assigned_reviewers = {reviewer, alt_reviewer, status};
        } else {
            assigned_reviewers = {reviewer, status};
        }

        onAssignReviewers(protocol.id, assigned_reviewers);
    }

    return (
        <>
            <tr>
                <td>{protocol.number}</td>
                <td>{protocol.title}</td>
                <td><span className='bg-warning text-center rounded-1 text-small p-1 fw-medium'>{protocol.status}</span></td>
                <td>{protocol.created_date}</td>
                <td>
                    <button type="button" className="btn btn-secondary rounded-1 me-2 fw-medium" onClick={handleMoreClick}>View</button>
                    <button type="button" className="btn btn-success rounded-1 fw-medium" data-bs-toggle="modal" data-bs-target={`#staticBackdrop-${protocol.number}`}>Add Reviewer(s)</button>
                </td>
            </tr>
            <div className="modal fade" id={`staticBackdrop-${protocol.number}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`staticBackdropLabel-${protocol.number}`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">{protocol.number}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label fw-medium">Reviewer 1:</label>
                                    <select id='study_type' className="form-select" name='study_type' aria-label="Default select example" value={reviewer} onChange={(e) => setReviewer(e.target.value)} required>
                                        <option value="" hidden>Select ...</option>
                                        {reviewers.filter(viewer => viewer.id != alt_reviewer).map(viewer => <option key={viewer.id} value={viewer.id}>{viewer.first_name} {viewer.last_name}</option>)}
                                    </select>
                                </div>
                                {protocol.number.search("A") === 5 &&
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label fw-medium">Reviewer 2:</label>
                                    <select id='study_type' className="form-select" name='study_type' aria-label="Default select example" value={alt_reviewer} onChange={(e) => setAltReviewer(e.target.value)} required>
                                        <option value="" hidden>Select ...</option>
                                        {reviewers.filter(viewer => viewer.id != reviewer).map(viewer => <option key={viewer.id} value={viewer.id}>{viewer.first_name} {viewer.last_name}</option>)}
                                    </select>
                                </div>}

                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary rounded-1">Save</button>
                                    <button type="button" className="btn btn-secondary rounded-1" data-bs-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
      );
}
 
export default PendingProtocol;