import { useLocation, useParams } from 'react-router-dom';
import NavBar from "./layout/NavBar";
import Profile from './layout/Profile';
import SideBar from "./researcher/SideBar";
import Dashboard from "./researcher/Dashboard";
import Footer from "./layout/Footer";
import NewStudyApplication from "./researcher/NewStudyApplication";
import ProtocolDetail from './researcher/ProtocolDetail';
import StudyTermination from './researcher/StudyTermination';
import AnnualReview from './researcher/AnnualReview';
import AdverseEvent from './researcher/AdverseEvent';
import ProtocolDeviation from './researcher/ProtocolDeviation';
import StudyExtension from './researcher/StudyExtension';
import AmendmentForm from './researcher/AmendmentForm';
import ApplicationWithdrawal from './researcher/ApplicationWithdrawal';
import DestructionForm from './researcher/DestructionForm';
import StudyReopening from './researcher/StudyReopening';
import DeathReport from './researcher/DeathReport';
import ResponseToComments from './researcher/ResponseToComments';
const Researcher = ({onNavToggle}) => {
    const location = useLocation();
    const { id } = useParams();
      
     
    return (
        <div className="sb-nav-fixed">
            <NavBar handleToggle={onNavToggle}/>
            <div id="layoutSidenav">
                <SideBar/>
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                        {
                            (() => {
                                switch (location.pathname) {
                                    case '/':
                                        return <Dashboard />;
                                    case '/submission':
                                        return <NewStudyApplication />;
                                    case `/protocol/${id}`:
                                        return <ProtocolDetail />;
                                    case `/response-to-comments/${id}`:
                                        return <ResponseToComments />;
                                    case '/profile':
                                        return <Profile />;
                                    case '/study-termination':
                                        return <StudyTermination />;
                                    case '/annual-review':
                                        return <AnnualReview/>
                                    case '/adverse-event':
                                        return <AdverseEvent/>
                                    case '/protocol-deviation':
                                        return <ProtocolDeviation/>
                                    case '/study-extension':
                                        return <StudyExtension/>
                                    case '/amendment-form':
                                        return <AmendmentForm/>
                                    case '/application-withdrawal':
                                        return <ApplicationWithdrawal/>
                                    case '/destruction-form':
                                        return <DestructionForm/>
                                    case '/study-reopening':
                                        return <StudyReopening/>
                                    case '/death-report':
                                        return <DeathReport/> 
                                }
                            })()
                        }                 
                        </div>
                    </main>
                    <Footer/>
                </div>
            </div>
        </div>
      );
}
 
export default Researcher;