import { useNavigate } from "react-router-dom";

const ReviewedProtocol = ({protocol}) => {
    const navigate = useNavigate();

    const handleMoreClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    return (
        <>
            <tr>
                <td>{protocol.number}</td>
                <td>{protocol.title}</td>
                <td><span className={`${protocol.status === "reviewed" ? "text-white bg-primary": "bg-primary-subtle"} text-center rounded-1 text-small p-1 fw-medium`}>{protocol.status}</span></td>
                <td>{protocol.created_date}</td>
                <td>
                    <button type="button" className="btn btn-secondary rounded-1 fw-medium" onClick={handleMoreClick}>{protocol.status === "reviewed" ? "Assess": "View"}</button>
                </td>
            </tr>
        </>
      );
}
 
export default ReviewedProtocol;