import { useState } from "react";

const StatisticalPlanning = ({onGetStats, onPrev}) => {
    const [reviewed, setReviewed] = useState(true);

    const [details, setDetails] = useState("");
    const [detailsError, setDetailsError] = useState("");

    const [sample_size, setSampleSize] = useState(0);
    const [sampleSizeError, setSampleSizeError] = useState("");

    const handleInputChange = (event, setInput, setInputError) => {
        setInput(event.target.value);
        setInputError("");
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (details.trim() === ""){
            setDetailsError("Enter required information");
            return;
        }

        if (sample_size <= 0) {
           setSampleSizeError("Enter the sample size");
           return; 
        }

        const stats = {reviewed, details, sample_size};

        onGetStats(stats);
    }

    return(
        <>
            <h1 className="my-4">Statistical Planning & Data Analysis  (*)</h1>
            <form onSubmit={handleSubmit}>
            <div className="card shadow p-3 mb-5 bg-white rounded">
                <span>(Check all that applies)</span>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th></th>
                            <th></th>
                            <td align="center"><b>YES</b></td>
                            <td align="center"><b>NO</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Has this project been reviewed by a professional statistician</td>
                            <td align="center">
                                <input className="form-check-input" type="radio" name="reviewed" checked={reviewed} onChange={(event) => setReviewed(event.target.checked)}/>
                            </td>
                            <td align="center">
                                <input className="form-check-input" type="radio" name="reviewed" checked={!reviewed} onChange={(event) => setReviewed(!event.target.checked)}/>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>{reviewed ? "If yes, provide details" : "Justify why no"}</td>
                            <td colSpan="3">
                                <input type="text" className={`form-control ${detailsError !== ""  ? 'is-invalid' : ''}`} name="details" value={details} onChange={(event) => handleInputChange(event, setDetails, setDetailsError)}/>
                                {detailsError && <div className="invalid-feedback">{detailsError}</div>}
                            </td>
                        </tr>
                        <tr>
                            <td>{reviewed.length > 0 ? 3 : 2}</td>
                            <td>Proposed sample size</td>
                            <td colSpan="3">
                                <input type="number" className={`form-control ${sampleSizeError !== ""  ? 'is-invalid' : ''}`} min="0" name="sample_size" value={sample_size} onChange={(event) => handleInputChange(event, setSampleSize, setSampleSizeError)}/>
                                {sampleSizeError && <div className="invalid-feedback">{sampleSizeError}</div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                        Previous
                    </button>
                    <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                        Next
                    </button>
                </div>
            </form>
        </>
    )
}

export default StatisticalPlanning;