import { useState } from "react";

const CollaboratingInstitutions = ({ onGetCollab, onPrev }) => {
  const [step, setStep] = useState(1);
  const [formErrors, setFormErrors] = useState({});

  const handleAdd = () => {
    setStep(step + 1);
  };

  const handleRemove = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Gather data from input fields
    const formData = [];
    const errors = {};

    for (let i = 1; i < step; i++) {
      const name = event.target.elements[`collabo_name[${i}]`].value;
      const focal_person = event.target.elements[`collabo_person[${i}]`].value;
      const telephone = event.target.elements[`collabo_tel[${i}]`].value;
      const email = event.target.elements[`collabo_email[${i}]`].value;

      // Validate input fields
      if (name.trim() === "") {
        errors[`collabo_name[${i}]`] = "Enter the institution name";
      }

      if (focal_person.trim() === "") {
        errors[`collabo_person[${i}]`] = "Enter the contact person";
      }

      if (telephone.trim() === "") {
        errors[`collabo_tel[${i}]`] = "Enter the telephone number";
      } else if (!/^\+\d{1,4}\d{10}$/.test(telephone)) {
        errors[`collabo_tel[${i}]`] = 'Enter a valid telephone number';
      }

      if (email.trim() === "") {
        errors[`collabo_email[${i}]`] = "Enter the email";
      } else if (!isValidEmail(email)) {
        errors[`collabo_email[${i}]`] = "Enter a valid email address";
      }

      formData.push({ name, focal_person, telephone, email });
    }

    if (Object.keys(errors).length === 0) {
      onGetCollab(formData);
    } else {
      setFormErrors(errors);
    }
  };

  const isValidEmail = (email) => {
    // You can implement your own email validation logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const renderInputClassName = (fieldName) => {
    return formErrors[fieldName] ? "form-control is-invalid" : "form-control";
  };

  const renderErrorMessage = (fieldName) => {
    return formErrors[fieldName] && (
      <div className="invalid-feedback">{formErrors[fieldName]}</div>
    );
  };

  const rows = [];

  for (let i = 1; i < step; i++) {
    rows.push(
      <tr key={i}>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_name[${i}]`)}
            name={`collabo_name[${i}]`}

          />
          {renderErrorMessage(`collabo_name[${i}]`)}
        </td>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_person[${i}]`)}
            name={`collabo_person[${i}]`}

          />
          {renderErrorMessage(`collabo_person[${i}]`)}
        </td>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_tel[${i}]`)}
            name={`collabo_tel[${i}]`}

          />
          {renderErrorMessage(`collabo_tel[${i}]`)}
        </td>
        <td>
          <input
            type="text"
            className={renderInputClassName(`collabo_email[${i}]`)}
            name={`collabo_email[${i}]`}

          />
          {renderErrorMessage(`collabo_email[${i}]`)}
        </td>
      </tr>
    );
  }

  return (
    <>
      <h1 className="my-4">Collaborating Institutions</h1>
      <form onSubmit={handleSubmit}>
        <div className="card shadow p-3 mb-5 bg-white rounded">
          <em>(Process ahead if not applicable)</em>
          <table className="table">
            <thead className="thead-light" id="collabo">
              <tr>
                <th scope="col">Institution</th>
                <th scope="col">Contact/ Focal Person (Name)</th>
                <th>Telephone #</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end my-3">
            <button
              className="btn btn-outline-primary me-md-2 rounded-1"
              type="button"
              onClick={handleAdd}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-lg"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </button>
            {step > 1 && (
              <button
                className="btn btn-outline-primary rounded-1"
                type="button"
                onClick={handleRemove}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-dash-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-primary btn-lg py-2 rounded-1"
            onClick={onPrev}
          >
            Previous
          </button>
          <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default CollaboratingInstitutions;
