import { useEffect } from "react";
import { create } from 'filepond';

const FileInput = ({id, error, handleInputChange, setFile, setFileError}) => {
    useEffect(() => {
        create(document.querySelector('input[type="file"]'));
      }, 
    []);

    return (
        <input
                type="file"
                className={`filepond ${error !== ""  ? 'is-invalid' : ''}`}
                name="filepond"
                id={id}
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                onChange={(event) => handleInputChange(event, setFile, setFileError)}
              />
      );
}
 
export default FileInput;