import { useState } from "react";

const ResearchTeam = ({study, research, investigators, onUpdateResearchTeam}) => {
    const [study_type, setStudyType] = useState(study);
    const [studyTypeError, setStudyTypeError] = useState("");

    const [principal_investigator, setPrincipalInvestigator] = useState(research.principal_investigator);
    const [principalInvestigatorError, setPrincipalInvestigatorError] = useState('');

    const [nationality, setNationality] = useState(research.nationality);
    const [nationalityError, setNationalityError] = useState('');

    const [existing_qualifications, setExistingQualifications] = useState(research.existing_qualifications);
    const [existingQualificationsError, setExistingQualificationsError] = useState('');

    const [academic_title, setAcademicTitle] = useState(research.academic_title);
    const [academicTitleError, setAcademicTitleError] = useState('');

    const [institution, setInstitution] = useState(research.institution);
    const [institutionError, setInstitutionError] = useState('');

    const [postal_address, setPostalAddress] = useState(research.postal_address);
    const [postalAddressError, setPostalAddressError] = useState('');

    const [email, setEmail] = useState(research.email);
    const [emailError, setEmailError] = useState('');

    const [telephone, setTelephone] = useState(research.telephone);
    const [telephoneError, setTelephoneError] = useState('');

    const [up_for_award, setUpForAward] = useState(research.up_for_award);
    const [upForAwardError, setUpForAwardError] = useState("");
    
    const [degree_type, setDegreeType] = useState(research.degree_type);
    const [degreeTypeError, setDegreeTypeError] = useState('');

    const [student_name, setStudentName] = useState(research.student_name);
    const [studentNameError, setStudentNameError] = useState('');

    const [student_varsity, setStudentVarsity] = useState(research.student_varsity);
    const [studentVarsityError, setStudentVarsityError] = useState('');

    const [student_info, setStudentInfo] = useState(research.student_info);
    const [studentInfoError, setStudentInfoError] = useState('');

    const [coInvestigators, setCoInvestigator] = useState(investigators);
    const [coInvestigatorErrors, setCoInvestigatorErrors] = useState({});

    const handleStudyChange = (event) => {
        setStudyType(event.target.value);
        setStudyTypeError('');
    };

    const handlePrincipleChange = (event) => {
        setPrincipalInvestigator(event.target.value);
		setPrincipalInvestigatorError('');
    };

    const handleNationalityChange = (event) => {
        setNationality(event.target.value);
		setNationalityError('');
    };

    const handleQualificationsChange = (event) => {
        setExistingQualifications(event.target.value);
		setExistingQualificationsError('');
    };

    const handleAcademicChange = (event) => {
        setAcademicTitle(event.target.value);
		setAcademicTitleError('');
    };

    const handleInstitutionChange = (event) => {
        setInstitution(event.target.value);
		setInstitutionError('');
    };

    const handleAddressChange = (event) => {
        setPostalAddress(event.target.value);
		setPostalAddressError('');
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
		setEmailError('');
    };

    const handleTelephoneChange = (event) => {
        setTelephone(event.target.value);
		setTelephoneError('');
    };

    const handleAwardChange = (event) => {
        setUpForAward(event.target.value);
		setUpForAwardError('');
    };

    const handleDegreeChange = (event) => {
        setDegreeType(event.target.value);
		setDegreeTypeError('');
    };

    const handleStudentChange = (event) => {
        setStudentName(event.target.value);
		setStudentNameError('');
    };

    const handleVarsityChange = (event) => {
        setStudentVarsity(event.target.value);
		setStudentVarsityError('');
    };

    const handleInfoChange = (event) => {
        setStudentInfo(event.target.value);
		setStudentInfoError('');
    };    
    
    const onSubmit = (event) => {
        event.preventDefault();

        if (study_type.trim() === '') {
            setStudyTypeError('Select the study type');
            return;
        }

        if (principal_investigator.trim() === '') {
            setPrincipalInvestigatorError('Enter the Principal Investigator');
            return;
        }

        if (nationality.trim() === '') {
            setNationalityError('Enter the nationality');
            return;
        }

        if (existing_qualifications.trim() === '') {
            setExistingQualificationsError('Enter existing qualifications');
            return;
        }

        if (academic_title.trim() === '') {
            setAcademicTitleError('Enter the academic title');
            return;
        }

        if (institution.trim() === '') {
            setInstitutionError('Enter the Institution & Department');
            return;
        }
        
        if (postal_address.trim() === '') {
            setPostalAddressError('Enter the postal address');
            return;
        }

        if (email.trim() === '') {
            setEmailError('Enter the email');
            return;
        }

        if (telephone.trim() === '') {
            setTelephoneError('Enter the telephone number');
            return;
        }

        if (!/^\+\d{1,4}\d{10}$/.test(telephone)) {
            setTelephoneError('Please enter valid telephone number');
            return;
        }

        if (up_for_award.trim() === '') {
            setUpForAwardError("Select option");
            return;
        }

        if (up_for_award === "Yes"){

            if (degree_type.trim() === '') {
                setDegreeType("Enter the degree type");
                return;
            }
        
            if (student_name.trim() === '') {
                setStudentNameError("Enter the student's name");
                return;
            }

            if (student_varsity.trim() === '') {
                setStudentVarsityError("Enter the student's university/insitution");
                return;
            }
        }

        if (student_info.trim() === '' && (study_type === "PhD" || study_type === "MSc" || study_type === "BSc" || up_for_award === "Yes")) {
            setStudentInfoError("Enter the student's # and year of study");
            return;
        }

        const co_investigators = [];
        const errors = {};

        for (let i = 0; i < investigators.length; i++) {
          const name = event.target.elements[`name[${i}]`].value ? event.target.elements[`name[${i}]`].value : coInvestigators[i].name;
          const qualifications = event.target.elements[`qualifications[${i}]`].value ? event.target.elements[`qualifications[${i}]`].value : coInvestigators[i].qualifications;
          const department = event.target.elements[`department[${i}]`].value ? event.target.elements[`department[${i}]`].value : coInvestigators[i].department;
    
          if (name.trim() === "") {
            errors[`name[${i}]`] = "Enter the name";
          }

          if (qualifications.trim() === "") {
            errors[`qualifications[${i}]`] = "Enter the qualifications";
          }

          if (department.trim() === "") {
            errors[`department[${i}]`] = "Enter the department";
          }
          
          co_investigators.push({id: investigators[i].id, protocol: investigators[i].protocol, name, qualifications, department});
        }

        if (Object.keys(errors).length !== 0) {
            setCoInvestigatorErrors(errors);
            return;
        }

        const research_team = {id: research.id, protocol: research.protocol, principal_investigator, nationality, existing_qualifications, academic_title, institution, postal_address, email, telephone, up_for_award, degree_type, student_name, student_varsity, student_info};

        console.log(study_type)
        console.log(co_investigators);
        console.log(research_team);
        
        onUpdateResearchTeam(research_team, co_investigators, {study_type});
    }

    const renderInputClassName = (fieldName) => {
        return coInvestigatorErrors[fieldName] ? "form-control is-invalid" : "form-control";
      };
    
    const renderErrorMessage = (fieldName) => {
        return coInvestigatorErrors[fieldName] && (
            <div className="invalid-feedback">{coInvestigatorErrors[fieldName]}</div>
        );
    };

    const rows = [];

    for (let i = 0; i < investigators.length; i++) {
        rows.push(
            <tr key={i}>
                <td>
                <input
                    type="text"
                    className={renderInputClassName(`name[${i}]`)}
                    name={`name[${i}]`}
                    placeholder={coInvestigators[i].name}

                />
                {renderErrorMessage(`name[${i}]`)}
                </td>
                <td>
                <input
                    type="text"
                    className={renderInputClassName(`qualifications[${i}]`)}
                    name={`qualifications[${i}]`}
                    placeholder={coInvestigators[i].qualifications}

                />
                {renderErrorMessage(`qualifications[${i}]`)}
                </td>
                <td>
                <input
                    type="text"
                    className={renderInputClassName(`department[${i}]`)}
                    name={`department[${i}]`}
                    placeholder={coInvestigators[i].department}

                />
                {renderErrorMessage(`department[${i}]`)}
                </td>
            </tr>
        );
    }

    return (
        <form onSubmit={onSubmit} method="post">
            <div className="shadow bg-secondary-subtle p-3 mb-5 rounded">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                        <tr className="my-3">
                            <td><b>Study type</b></td>
                            <td colSpan="2">
                                <select id='study_type' className={`form-select ${studyTypeError !== ''  ? 'is-invalid' : ''}`} name='study_type' aria-label="Default select example" value={study_type} onChange={handleStudyChange}>
                                    <option value="" disabled hidden>Select ...</option>
                                    <option value="PhD">PhD</option>
                                    <option value="MSc">MSc</option>
                                    <option value="BSc">BSc</option>
                                    <option value="Exempted from Ethics Review">Exempted from Ethics Review</option>
                                    <option value="Fast Track Reviews">Fast Track Reviews</option>
                                    <option value="Organisational Studies">Organisational Studies</option>
                                    <option value="Individual Research">Individual Research</option>
                                    <option value="Clinical Trials">Clinical Trials</option>
                                </select>
                                {studyTypeError && <div className="invalid-feedback">{studyTypeError}</div>}
                            </td>
                        </tr>
                        <tr>
                        <td>Name of Principal Investigator (P.I)</td>
                        <td colSpan="2">
                            <input type="text" className={`form-control ${principalInvestigatorError !== ''  ? 'is-invalid' : ''}`} id="principal_investigator" name='principal_investigator' value={principal_investigator} onChange={handlePrincipleChange}/>
                            {principalInvestigatorError && <div className="invalid-feedback">{principalInvestigatorError}</div>}
                        </td>
                        </tr>
                        <tr>
                            <td>Nationality of P.I</td>
                            <td colSpan="2">
                            <input type="text" className={`form-control ${nationalityError !== ''  ? 'is-invalid' : ''}`} id="nationality" name='nationality' value={nationality} onChange={handleNationalityChange}/>
                            {nationalityError && <div className="invalid-feedback">{nationalityError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Existing Qualifications</td>
                        <td colSpan="2">
                            <input type="text" className={`form-control ${existingQualificationsError !== ''  ? 'is-invalid' : ''}`} id="existing_qualifications" name='existing_qualifications' value={existing_qualifications} onChange={handleQualificationsChange}/>
                            {existingQualificationsError && <div className="invalid-feedback">{existingQualificationsError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Academic Title</td>
                        <td colSpan="2">
                            <input type="text" className={`form-control ${academicTitleError !== ''  ? 'is-invalid' : ''}`} id="academic_title" name='academic_title' value={academic_title} onChange={handleAcademicChange}/>
                            {academicTitleError && <div className="invalid-feedback">{academicTitleError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Institution & Dept.</td>
                        <td colSpan="2">
                            <input type="text" className={`form-control ${institutionError !== ''  ? 'is-invalid' : ''}`} id="institution" name='institution' value={institution} onChange={handleInstitutionChange}/>
                            {institutionError && <div className="invalid-feedback">{institutionError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Postal address</td>
                        <td colSpan="2">
                            <input type="text" className={`form-control ${postalAddressError !== ''  ? 'is-invalid' : ''}`} id="postal_address" name='postal_address' value={postal_address} onChange={handleAddressChange}/>
                            {postalAddressError && <div className="invalid-feedback">{postalAddressError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Email</td>
                        <td colSpan="2">
                            <input type="email" className={`form-control ${emailError !== ''  ? 'is-invalid' : ''}`} id="email" name='email' value={email} onChange={handleEmailChange}/>
                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>Telephone No.</td>
                        <td colSpan="2">
                            <input type="text" className={`form-control ${telephoneError !== ''  ? 'is-invalid' : ''}`} id="telephone" name='telephone' value={telephone} onChange={handleTelephoneChange}/>
                            {telephoneError && <div className="invalid-feedback">{telephoneError}</div>}
                        </td>
                        </tr>
                        <tr>
                        <td>
                            Is this research expected to lead to the award of a higher degree for the PI or any other research team member?
                        </td>
                        <td colSpan="2">
                            <select name="up_for_award" id="up_for_award" value={up_for_award} className={`form-select ${upForAwardError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" onChange={handleAwardChange}>
                                <option value="" disabled hidden>Select ...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {upForAwardError && <div className="invalid-feedback">{upForAwardError}</div>}
                        </td>
                        </tr>
                        {up_for_award === "Yes" && <><tr id="degree_var">
                            <td>
                                Degree Type
                            </td>
                            <td>
                                <select id="degree_type" name='degree_type' className={`form-select ${degreeTypeError !== ''  ? 'is-invalid' : ''}`} aria-label="Default select example" value={degree_type} onChange={handleDegreeChange}>
                                    <option value="" disabled hidden>Select Degree ...</option>
                                    <option value="Undergraduate">Undergraduate</option>
                                    <option value="MSc/MA/MMed/MPhil">MSc/MA/MMed/MPhil</option>
                                    <option value="Other">Other</option>
                                </select>
                                {degreeTypeError && <div className="invalid-feedback">{degreeTypeError}</div>}
                            </td>
                        </tr>
                        <tr id='name_11'>
                            <td>Name of student if not the PI</td>
                            <td>
                                <input type="text" className={`form-control ${studentNameError !== ''  ? 'is-invalid' : ''}`} id="student_name" name='student_name' value={student_name} onChange={handleStudentChange}/>
                                {studentNameError && <div className="invalid-feedback">{studentNameError}</div>}
                            </td>
                        </tr>
                        <tr id='name_12'>
                            <td>University/Institution where student is registered</td>
                            <td>
                                <input type="text" className={`form-control ${studentVarsityError !== ''  ? 'is-invalid' : ''}`} id="student_varsity" name='student_varsity' value={student_varsity} onChange={handleVarsityChange}/>
                                {studentVarsityError && <div className="invalid-feedback">{studentVarsityError}</div>}
                            </td>
                        </tr></>}
                        {(study_type === "PhD" || study_type === "MSc" || study_type === "BSc" || up_for_award === "Yes") && <tr id='name_13'>
                            <td>Student # and Year of Study</td>
                            <td>
                                <input type="text" className={`form-control ${studentInfoError !== ''  ? 'is-invalid' : ''}`} id="student_info" name='student_info' value={student_info} onChange={handleInfoChange}/>
                                {studentInfoError && <div className="invalid-feedback">{studentInfoError}</div>}
                            </td>
                        </tr>}
                        {rows.length > 0 && <><tr>
                            <th scope="col">Co-investigators Names</th>
                            <th scope="col">Qualifications</th>
                            <th scope="col">Institution/Department</th>
                        </tr>
                        {rows}
                        </>
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>
        </form>
    )
}

export default ResearchTeam;