import { useNavigate } from "react-router-dom";

const RespondedProtocol = ({protocol, onAssignReviewers}) => {
    const navigate = useNavigate();

    const handleMoreClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    return (
        <>
            <tr>
                <td>{protocol.number}</td>
                <td>{protocol.title}</td>
                <td><span className="bg-dark text-white text-center rounded-1 text-small p-1 fw-medium">{protocol.status}</span></td>
                <td>{protocol.created_date}</td>
                <td>
                    <button type="button" className="btn btn-secondary rounded-1 fw-medium me-2" onClick={handleMoreClick}>View</button>
                    <button type="button" className="btn btn-success rounded-1 fw-medium" onClick={() => onAssignReviewers(protocol.id ,{status: "assigned", comment: null, alt_comment: null, compliance_comment: null})}>Forward to Reviewer(s)</button>
                </td>
            </tr>
        </>
      );
}
 
export default RespondedProtocol;