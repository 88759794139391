import React, { useState } from "react";
import DeclinedProtocol from "./DeclinedProtocol";

const DeclinedProtocols = ({protocols, reviewers, handleAssignReviewers, message}) => {
  const recordsPerPage = 10;
  const totalPages = Math.ceil(protocols.length / recordsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedProtocols = protocols.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  return (
    <div className="card mb-4 shadow p-3 bg-white rounded">
      <div className="card-header p-4">
        <h4 className="card-title">Declined Submissions</h4>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Protocol Number</th>
            <th scope="col">Title</th>
            <th scope="col">Status</th>
            <th scope="col">Date of Submission</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedProtocols.map(protocol => (
            <DeclinedProtocol
              key={protocol.id}
              protocol={protocol}
              reviewers={reviewers}
              onAssignReviewers={handleAssignReviewers}
              message={message}
            />
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DeclinedProtocols;
