import Routers from './Routers';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';



function App() {
  return (
    <div>
      <main>
        <Routers />
      </main>
    </div>
  );
}

export default App;
