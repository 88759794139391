import logo from '../../logo.gif';
import { Link } from 'react-router-dom';

const NavBar = ({handleToggle}) => {
    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark" style={{backgroundColor: "#172130"}}>
            {/* Navbar Brand */}
            <div className="m-2 p-2 bg-primary rounded-pill">
                <Link to="/" className="py-2 px-2">
                    <img src={logo} alt="MRCZ Logo" className="rounded-circle" width="30" height="30" />
                    <span className="font-weight-bold text-white px-2">MRCZ PORTAL</span>
                </Link>
            </div>
            {/* Sidebar Toggle*/}
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" onClick={handleToggle}><i className="fas fa-bars"></i></button>
            {/* Navbar */}
        </nav>
      );
}
 
export default NavBar;