import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext();

// const authenticationEndpoint = 'https://app.mrcz.org.zw/api/token/';
// const userEndpoint = 'https://app.mrcz.org.zw/api/accounts';
// const refreshEndpoint = 'https://app.mrcz.org.zw/api/token/refresh/';

const authenticationEndpoint = 'https://app.mrcz.org.zw/api/token/';
const userEndpoint = 'https://app.mrcz.org.zw/api/accounts';
const refreshEndpoint = 'https://app.mrcz.org.zw/api/token/refresh/';

export const AuthProvider = (props) => {
  const [state, setState] = useState({
    isLoggedIn: false,
    user: null
  });
  const [loginError, setLoginError] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const navigate = useNavigate();

  const checkAuthenticated = async () => {
    const token = localStorage.getItem('access');
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        await refreshToken();
      } else {
        getUserById(decodedToken.user_id);
      }
    }
  };

  const handleLogin = async (userDetails) => {
    return new Promise((resolve, reject) => {
      fetch(authenticationEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userDetails)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          localStorage.setItem('access', data.access);
          localStorage.setItem('refresh', data.refresh);
          const decodedToken = jwt_decode(data.access);
          getUserById(decodedToken.user_id);
          setLoginError(false);
          resolve(loginError);
        })
        .catch(error => {
          console.error(error);
          setLoginError(true);
          resolve(loginError);
          alert("Incorrect email or password");
        });
    });
  };


  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    setState({
      isLoggedIn: false,
      user: null
    });
    navigate("/");
  };

  const refreshToken = async () => {
    const refresh = localStorage.getItem('refresh');
    if (!refresh) {
      handleLogout();
      return;
    }

    fetch(refreshEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refresh })
    })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('access', data.access);
        const decodedToken = jwt_decode(data.access);
        getUserById(decodedToken.user_id);
      })
      .catch(error => {
        console.error(error);
        handleLogout();
      });
  };

  const getUserById = async (userId) => {
    setUserLoading(true); // Set userLoading to true before fetching user data
    fetch(`${userEndpoint}/${userId}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setState({
          isLoggedIn: true,
          user: data
        });
        console.log('Logged In');
        setUserLoading(false); // Set userLoading to false after user data has been fetched
      })
      .catch(error => {
        console.error(error);
        setUserLoading(false); // Set userLoading to false if there was an error fetching user data
      });
  };


  return (
    <AuthContext.Provider value={{ state, checkAuthenticated, handleLogin, handleLogout, userLoading }}>
      {props.children}
    </AuthContext.Provider>
  );
}


