import { useState } from "react";

const ConsentProcess = ({ onGetConsent, onPrev }) => {
  const [step, setStep] = useState(1);
  const rows = [];
  const [has_consent_form, setConsentForm] = useState("");
  const [hasConsentFormError, setConsentFormError] = useState("");
  const [languageErrors, setLanguageErrors] = useState([]);

  const [written, setWritten] = useState(false);
  const [verbal, setVerbal] = useState(false);
  const [english, setEnglish] = useState(false);

  const handleAdd = () => {
    setStep(step + 1);
  };

  const handleRemove = () => {
    if (step > 0) setStep(step - 1);
  };

  for (let i = 1; i < step; i++) {
    const languageError = languageErrors[i] || ""; // Get the error message for the corresponding language input
    rows.push(
      <div key={i}>
        <input
          type="text"
          className={`form-control mb-2 ${languageError !== "" ? "is-invalid" : ""}`}
          name={`lang[${i}]`}
        />
        {languageError && <div className="invalid-feedback">{languageError}</div>}
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (has_consent_form.trim() === "") {
      setConsentFormError("Select option");
      return;
    }

    let consent = {has_consent_form};

    if (has_consent_form === "Yes") {
        const errors = []; // Array to store language input errors
        const languages = [];
        for (let i = 1; i < step; i++) {
            const language = e.target.elements[`lang[${i}]`].value;
            languages.push(language);

            // Perform validation on each language input
            if (language.trim() === "") {
            errors[i] = "Language is required";
            }
        }

        if (errors.length > 0) {
            setLanguageErrors(errors);
            return;
        }

        const local_languages = languages.join(", ");

        consent = { has_consent_form, written, verbal, english, local_languages };      
    }

    onGetConsent(consent);
  };

  return (
    <>
      <h1 className="my-4">Consent Process</h1>
      <form onSubmit={handleSubmit}>
        <div className="card shadow p-3 mb-5 bg-white rounded">
          <span>(Check all that applies)</span>
          <table className="table">
            <tbody className="thead-light">
              <tr>
                <td>Does the research include the consent form?</td>
                <td>
                  <select
                    id="has_consent_form"
                    name="has_consent_form"
                    className={`form-select ${hasConsentFormError !== "" ? "is-invalid" : ""}`}
                    aria-label="Default select example"
                    value={has_consent_form}
                    onChange={(event) => {
                      setConsentForm(event.target.value);
                      setConsentFormError("");
                    }}
                  >
                    <option value="" disabled hidden>
                      Select ...
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {hasConsentFormError && <div className="invalid-feedback">{hasConsentFormError}</div>}
                </td>
              </tr>
            </tbody>
            {has_consent_form === "Yes" && (
              <tbody id="consent_involved">
                <tr>
                  <td>Written</td>
                  <td>
                    <input className="form-check-input" type="checkbox" name="written" checked={written} onChange={() => setWritten(!written)} />
                  </td>
                </tr>
                <tr>
                  <td>Verbal/Oral</td>
                  <td>
                    <input className="form-check-input" type="checkbox" name="verbal" checked={verbal} onChange={() => setVerbal(!verbal)} />
                  </td>
                </tr>
                <tr>
                  <td>Consent Language: English</td>
                  <td>
                    <input className="form-check-input" type="checkbox" name="english" checked={english} onChange={() => setEnglish(!english)} />
                  </td>
                </tr>
                <tr>
                  <td>Local languages</td>
                  <td>
                    {rows}
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end my-3">
                      <button className="btn btn-outline-primary me-md-2 rounded-1" type="button" onClick={handleAdd}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                      </button>
                      {step > 1 && (
                        <button className="btn btn-outline-primary rounded-1" type="button" onClick={handleRemove}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
            Previous
          </button>
          <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default ConsentProcess;
