import { useNavigate } from "react-router-dom";

const Protocol = ({protocol, onDecline, user}) => {
    const navigate = useNavigate();
    var badge_bg = 'bg-secondary';

    const handleReviewClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    const handleDeclineClick = () => {
        if (protocol.reviewer === user.id){
            onDecline(protocol.id, {reviewer: null, status: "declined"});
            return
        }

        if (protocol.alt_reviewer === user.id){
            onDecline(protocol.id, {alt_reviewer: null, status: "declined"});
            return
        }
    }

    (() => {
        switch (protocol.status) { 
            case 'assigned':
                badge_bg = 'bg-secondary text-white';
                break;
            case 'reviewed':
                badge_bg = 'bg-primary text-white';
                break;
            case 'partially reviewed':
                badge_bg = 'bg-primary-subtle';
                break;
            default:
                return null;
        }
    })()

    return (
        <tr>
            <td>{protocol.number}</td>
            <td>{protocol.title}</td>
            <td><span className={`${badge_bg} text-center rounded-1 text-small p-1 fw-medium`}>{protocol.status}</span></td>
            <td>{protocol.study_type}</td>
            <td>{protocol.created_date}</td>
            {(protocol.reviewer === user.id && protocol.comment === null || protocol.alt_reviewer === user.id && protocol.alt_comment === null) && <td>
                <button type="button" className="btn btn-success rounded-1 me-2 fw-medium" onClick={handleReviewClick}>Review</button>
                {protocol.status != "partially reviewed" && <button type="button" className="btn btn-danger rounded-1 fw-medium" onClick={handleDeclineClick}>Decline</button>}
            </td>}
        </tr>
    );
}
 
export default Protocol;
