import { useNavigate } from "react-router-dom";

const AcceptedProtocol = ({protocol, onDelete}) => {
    const navigate = useNavigate();

    const handleMoreClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    return (
        <>
            <tr>
                <td>{protocol.number}</td>
                <td>{protocol.title}</td>
                <td><span className="bg-success text-white text-center rounded-1 text-small p-1 fw-medium">{protocol.status}</span></td>
                <td>{protocol.created_date}</td>
                <td>
                    <button type="button" className="btn btn-secondary rounded-1 fw-medium me-2" onClick={handleMoreClick}>View</button>
                    <button type="button" className="btn btn-danger rounded-1 fw-medium" data-bs-toggle="modal" data-bs-target="#staticBackdrop-delete">Delete</button>
                </td>
            </tr>
            <div className="modal fade" id="staticBackdrop-delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel-delete" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Delete submission</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this submission?</p>
                        </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn-primary rounded-1" onClick={() => onDelete(protocol)}>Yes</button>
                                <button type="button" className="btn btn-secondary rounded-1" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
      );
}
 
export default AcceptedProtocol;