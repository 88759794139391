import { useLocation, useParams } from 'react-router-dom';
import NavBar from "./layout/NavBar";
import Profile from './layout/Profile';
import EditProfile from './layout/EditProfile';
import Footer from "./layout/Footer";
import SideBar from './compliance/SideBar';
import Dashboard from './compliance/Dashboard';
import ProtocolDetail from './compliance/ProtocolDetail';


const Compliance = ({onNavToggle}) => {
    const location = useLocation();
    const { id } = useParams();

    return (
        <div className="sb-nav-fixed">
            <NavBar handleToggle={onNavToggle}/>
            <div id="layoutSidenav">
                <SideBar/>
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                        {
                            (() => {
                                switch (location.pathname) {
                                case '/':
                                    return <Dashboard />;
                                case `/protocol/${id}`:
                                    return <ProtocolDetail />;
                                case '/profile':
                                    return <Profile />;
                                case '/edit-profile':
                                    return <EditProfile />;
                                default:
                                    return <Dashboard />;
                                }
                            })()
                        }                 
                        </div>
                    </main>
                    <Footer/>
                </div>
            </div>
        </div>
      );
}
 
export default Compliance;