import { useState } from "react";

const ConflictOfInterest = ({conflict, onUpdateConflict}) => {
    const [conflict_of_interest, setConflictOfInterest] = useState(conflict.conflict_of_interest);
    const [potential_conflict_management_plan, setPotentialConflictManagementPlan] = useState(null);
    const [potentialConflictManagementPlanError, setPotentialConflictManagementPlanError] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (conflict_of_interest && potential_conflict_management_plan === null){
            setPotentialConflictManagementPlanError("Attach the Potential Conflict Management Plan");
            return;
        }

        if (!conflict_of_interest) {
            setPotentialConflictManagementPlan(null);
        }

        onUpdateConflict(conflict.id, conflict.protocol, conflict_of_interest, potential_conflict_management_plan);
    }


    return(
        <form onSubmit={handleSubmit}>
            <div className="bg-secondary-subtle shadow p-3 mb-5 bg-white rounded">
                <div>
                    I declare that all potential conflicts of interest regarding my application for ethics approval to conduct this study have been declared in the protocol/proposal.
                    Conflict of Interest includes but not limited to reporting :
                    <ul>
                        <li>Having a financial and/or business interests in the source of funding </li>
                        <li>Being a consultant for the source of funding</li>
                        <li>Receiving funding from a sponsor that may be affected by the research reported in the study</li>
                    </ul>
                    Yes&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" id="conflict_yes" name="conflict" value="Yes" checked={conflict_of_interest} onChange={(event) => setConflictOfInterest(event.target.value === "Yes")} required/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    No&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" id="conflict_no" name="conflict" value="No" checked={!conflict_of_interest} onChange={(event) => setConflictOfInterest(event.target.value === "Yes")} required/><br/><br/>
                    {conflict_of_interest && (
                    <div>
                        <p>If Yes, please give details in a separate document that show that there is a plan in place for managing any potential conflicts of interest arising. I understand and accept that all information pertaining to this application is a true reflection of the project proposed and I take full responsibility should there be any transgression.</p>
                        <div className="mb-4">
                            <label htmlFor="potential_conflict_management_plan" className="form-label fs-5 fw-medium">Potential Conflict Management Plan</label>
                            <br/>
                            Currently: <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover mx-2 fw-medium" href={conflict.potential_conflict_management_plan} target='_blank' rel="noreferrer">{conflict.potential_conflict_management_plan?.substring(conflict.potential_conflict_management_plan?.lastIndexOf('/') + 1)} </a>
                            <br/><br/>
                            Change:  <input className={`bg-light ${potentialConflictManagementPlanError !== ""  ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx" id="potential_conflict_management_plan" name="potential_conflict_management_plan" onChange={(event) => {
                                setPotentialConflictManagementPlan(event.target.files[0]);
                                setPotentialConflictManagementPlanError("");
                                }}/>
                            {potentialConflictManagementPlanError && <div className="invalid-feedback">{potentialConflictManagementPlanError}</div>}
                        </div>
                    </div>
                    )}

                </div>
                <br/>
                <br/>
            </div>
            <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-primary bg-gradient btn-lg py-2 rounded-1 fw-medium" type="submit">Save</button>
            </div>
        </form>
    )
}

export default ConflictOfInterest;