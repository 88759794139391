import React, { useState } from "react";
import PendingProtocol from "./PendingProtocol";
import AssignedProtocol from "./AssignedProtocol";
import ReviewedProtocol from "./ReviewedProtocol";
import DeclinedProtocol from "./DeclinedProtocol";
import AcceptedProtocol from "./AcceptedProtocol";
import RejectedProtocol from "./RejectedProtocol";
import RespondedProtocol from "./RespondedProtocol";

const Protocols = ({ protocols, reviewers, handleAssignReviewers, message, onDelete }) => {
  const recordsPerPage = 10;
  const totalPages = Math.ceil(protocols.length / recordsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedProtocols = protocols.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  return (
    <div className="card mb-4 shadow p-3 bg-white rounded">
      <div className="card-header p-4">
        <h4 className="card-title">All Submissions</h4>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Protocol Number</th>
            <th scope="col">Title</th>
            <th scope="col">Status</th>
            <th scope="col">Date of Submission</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedProtocols.map((protocol) => {
            switch (protocol.status) {
                case 'pending':
                    return <PendingProtocol key={protocol.id} protocol={protocol} reviewers={reviewers} onAssignReviewers={handleAssignReviewers} message={message} />;
                case 'assigned':
                    return <AssignedProtocol key={protocol.id} protocol={protocol} />
                case 'reviewed':
                case 'partially reviewed':
                    return <ReviewedProtocol key={protocol.id} protocol={protocol} />
                case 'declined':
                    return <DeclinedProtocol key={protocol.id} protocol={protocol} reviewers={reviewers} onAssignReviewers={handleAssignReviewers} message={message}/>
                case 'accepted':
                    return <AcceptedProtocol key={protocol.id} protocol={protocol} onDelete={onDelete}/>
                case 'rejected':
                    return <RejectedProtocol key={protocol.id} protocol={protocol} onDelete={onDelete}/>
                case 'response':
                    return <RespondedProtocol key={protocol.id} protocol={protocol} onAssignReviewers={handleAssignReviewers} />
                default:
                    return null
            }
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        <ul className="pagination">
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Protocols;
