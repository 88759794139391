import { useNavigate } from "react-router-dom";

const AssignedProtocol = ({protocol}) => {
    const navigate = useNavigate();

    const handleViewClick = () => {
        navigate(`/protocol/${protocol.id}`, { state: { protocol } });
    };

    return (
        <>
            <tr>
                <td>{protocol.number}</td>
                <td>{protocol.title}</td>
                <td><span className="bg-secondary text-white text-center rounded-1 text-small p-1 fw-medium">{protocol.status}</span></td>
                <td>{protocol.created_date}</td>
                <td>
                    <button type="button" className="btn btn-secondary rounded-1 fw-medium" onClick={handleViewClick}>View</button>
                </td>
            </tr>
        </>
      );
}
 
export default AssignedProtocol;