import { useState } from "react";

const RiskDetermination = ({onGetRiskDetermination, onPrev}) => {
    const [human_exposure_to_ionizing_radiation, setHumanExposureToIonizingRadiation] = useState(false);
    const [fetal_tissue, setFetalTissue] = useState(false);
    const [investigational_new_drug, setInvestigationaNewDrug] = useState(false);
    const [investigational_new_device, setInvestigationaNewDevice] = useState(false);
    const [existing_data_available, setExistingDataAvailable] = useState(false);
    const [existing_data_not_available, setExistingDataNotAvailable] = useState(false);
    const [public_behavior_observation, setPublicBehaviorObservation] = useState(false);
    const [information_recorded, setInformationRecorded] = useState(false);
    const [sensitive_aspects, setSensitiveAspects] = useState(false);
    const [criminal_prosecution_risk, setCriminalProsecutionRisk] = useState(false);
    const [damage_reputation, setDamageReputation] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const risk = {human_exposure_to_ionizing_radiation, fetal_tissue, investigational_new_drug, investigational_new_device, existing_data_available, existing_data_not_available, public_behavior_observation, information_recorded, sensitive_aspects, criminal_prosecution_risk, damage_reputation};

        onGetRiskDetermination(risk);
    }

    return(
        <>
            <h1 className="my-4">Determination of Risk  (*)</h1>
            <form onSubmit={handleSubmit}>
            <div className="card shadow p-3 mb-5 bg-white rounded">
            <span>(Check all that applies)</span>
            <table className="table">
                <thead className="thead-light bg-light">
                    <tr>
                    <th>#</th>
                    <th></th>
                    <th>Check</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Human exposure to ionizing radiation</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="human_exposure_to_ionizing_radiation" checked={human_exposure_to_ionizing_radiation} onChange={() => setHumanExposureToIonizingRadiation(!human_exposure_to_ionizing_radiation)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>Fetal tissue or abortus</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="fetal_tissue" checked={fetal_tissue} onChange={() => setFetalTissue(!fetal_tissue)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Investigational new drug</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="investigational_new_drug" checked={investigational_new_drug} onChange={() => setInvestigationaNewDrug(!investigational_new_drug)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Investigational new device</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="investigational_new_device" checked={investigational_new_device} onChange={() => setInvestigationaNewDevice(!investigational_new_device)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>Existing data available via public archives/sources</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="existing_data_available" checked={existing_data_available} onChange={() => setExistingDataAvailable(!existing_data_available)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>Existing data not available via public archives</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="existing_data_not_available" checked={existing_data_not_available} onChange={() => setExistingDataNotAvailable(!existing_data_not_available)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>7</td>
                    <td>Observation of public behavior</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="public_behavior_observation" checked={public_behavior_observation} onChange={() => setPublicBehaviorObservation(!public_behavior_observation)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>Is the information going to be recorded in such a way that participants can be identified?</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="information_recorded" checked={information_recorded} onChange={() => setInformationRecorded(!information_recorded)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>9</td>
                    <td>Does the research deal with sensitive aspects of the participants behavior, sexual behavior, alcohol use or illegal conduct such as drug use?</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="sensitive_aspects" checked={sensitive_aspects} onChange={() => setSensitiveAspects(!sensitive_aspects)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>Could the information recorded about the individual if it became known outside of the research, place the participants at risk of criminal prosecution or civil liability?</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="criminal_prosecution_risk" checked={criminal_prosecution_risk} onChange={() => setCriminalProsecutionRisk(!criminal_prosecution_risk)}/>
                    </td>
                    </tr>
                    <tr>
                    <td>11</td>
                    <td>Could the information recorded about the individual if it became known outside of the research, damage the participant’s financial standing, reputation and employability?</td>
                    <td align="center">
                        <input className="form-check-input" type="checkbox" name="damage_reputation" checked={damage_reputation} onChange={() => setDamageReputation(!damage_reputation)}/>
                    </td>
                    </tr>
                </tbody>
                </table>
                </div>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                        Previous
                    </button>
                    <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                        Next
                    </button>
                </div>
            </form>
        </>
    )
}

export default RiskDetermination;