const Submission = ({onPrev, onSubmit}) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    }

    return(
        <>
            <h1 className="my-4">Assurance Sheet</h1>
            <form onSubmit={handleSubmit}>
            <div className="card shadow p-3 mb-5 bg-white rounded">
                <h5>Principal Investigator's Assurance Statement:</h5>
                <p>I certify that the information given by me is correct to the best of my knowledge, I am familiar with and understand the Medical Research Council of Zimbabwe's policy concerning research involving human participants (CIOMS Guidelines or Helsinki Declaration) and I agree:</p>
                <span>Please check all that applies</span>
                <ol>
                <li>
                    <input className="form-check-input mx-2" type="checkbox" value="" name="vari_conduct" required/>
                    To accept responsibility for the scientific and ethical conduct of this research study
                </li>
                <li>
                    <input className="form-check-input mx-2" type="checkbox" value="" name="vari_before" required/>
                    To obtain prior approval from the relevant IRB as well as the MRCZ before amending or altering the research protocol or implementing changes in the approved consent form
                </li>
                <li>
                    <input className="form-check-input mx-2" type="checkbox" value="" name="vari_srs" required/>
                    To immediately report to the relevant IRB and the MRCZ any serious adverse reactions and/or unanticipated effects on participants which may occur as a result of this study
                </li>
                <li>
                    <input className="form-check-input mx-2" type="checkbox" value="" name="vari_annu"/>
                    To complete and submit the Continuing annual Review Form annually (when due) as well as the Final/Study termination form at the end of the proposed study
                </li>
                <li>
                    <input className="form-check-input mx-2" type="checkbox" value="" name="vari_stan"/>
                    To submit the final study report to the MRCZ using standard form (MRCZ Termination Form 105).
                </li>
                <li>
                    <input className="form-check-input mx-2" type="checkbox" value="" name="vari_protocol"/>
                    To pay one percent levy to the MRCZ upon approval of my protocol (for study monitoring and  general research participants protection requirements).
                </li>
                </ol>
                </div>
                <div className="d-flex justify-content-between">
                <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                    Previous
                </button>
                <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                    Submit
                </button>
            </div>
            </form>
        </>
    )
}

export default Submission