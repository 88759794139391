import { useLocation, useParams } from 'react-router-dom';
import NavBar from "./layout/NavBar";
import Profile from './layout/Profile';
import SideBar from "./reviewer/SideBar";
import Dashboard from "./reviewer/Dashboard";
import ProtocolDetail from './reviewer/ProtocolDetail';
import Footer from "./layout/Footer";


const Reviewer = ({onNavToggle}) => {
    const location = useLocation();
    const { id } = useParams();
     
    return (
        <div className="sb-nav-fixed">
            <NavBar handleToggle={onNavToggle}/>
            <div id="layoutSidenav">
                <SideBar/>
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container" style={{width: "90%"}}>
                    {
                            (() => {
                                switch (location.pathname) {
                                case '/':
                                    return <Dashboard />;
                                case `/protocol/${id}`:
                                    return <ProtocolDetail />;
                                case '/profile':
                                    return <Profile />;
                                default:
                                    return <Dashboard />;                                  
                                }
                            })()
                        } 
                        </div>
                    </main>
                    <Footer/>
                </div>
            </div>
        </div>
      );
}
 
export default Reviewer;