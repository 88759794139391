import { useState } from "react";


const Training = ({onGetTraining, onPrev}) => {
    const today = new Date();

    const [research_ethics, setResearchEthics] = useState("");

    const [ich_gcp, setIchGcp] = useState("");

    const [good_clinical_lab_practices, setGoodClinicalLabPractices] = useState("");

    const [good_data_management_practices, setGoodDataManagementPractices] = useState("");

    const [other, setOther] = useState("");

    const [research_ethics_date, setResearchEthicsDate] = useState("");
    const [researchEthicsDateError, setResearchEthicsDateError] = useState("");
    
    const [ich_gcp_date, setIchGcpDate] = useState("");
    const [ichGcpDateError, setIchGcpDateError] = useState("");

    const [good_clinical_lab_practices_date, setGoodClinicalLabPracticesDate] = useState("");
    const [goodClinicalLabPracticesDateError, setGoodClinicalLabPracticesDateError] = useState("");

    const [good_data_management_practices_date, setGoodDataManagementPracticesDate] = useState("");
    const [goodDataManagementPracticesDateError, setGoodDataManagementPracticesDateError] = useState("");
    
    const [other_date, setOtherDate] = useState("");
    const [otherDateError, setOtherDateError] = useState("");

    const handleInputChange = (event, setText) => {
        setText(event.target.value);
    }

    const handleInputDateChange = (event, setDate, setDateError) => {
        setDate(event.target.value);
        setDateError("");
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let training = {research_ethics, ich_gcp, good_clinical_lab_practices, good_data_management_practices, other};

        if (research_ethics === "No") {
            if(research_ethics_date.trim() === ""){
                setResearchEthicsDateError('Select date');
                return
            }

            training = {...training, research_ethics_date};
        }

        if (ich_gcp === "No") {
            if (ich_gcp_date.trim() === "") {
                setIchGcpDateError('Select date');
                return
            }
            training = {...training, ich_gcp_date};
        }

        if (good_clinical_lab_practices === "No") {
            if (good_clinical_lab_practices_date === "") {
                setGoodClinicalLabPracticesDateError('Select date');
                return
            }
            training = {...training, good_clinical_lab_practices_date};
        }

        if (good_data_management_practices === "No") {
            if (good_data_management_practices_date === "") {
                setGoodDataManagementPracticesDateError('Select date');
                return
            }
            training = {...training, good_data_management_practices_date};
        }

        if (other === "No") {
            if (other_date === ""){
                setOtherDateError('Select date');
                return
            }
            training = {...training, other_date};
        }

        onGetTraining(training);
    }


    return(
        <>
            <h1 className="my-4">TRAINING  (*)</h1>
            <form onSubmit={handleSubmit}>
            <div className="card shadow p-3 mb-5 bg-white rounded"> 
            <span>(Check all that applies)</span>
            <table className="table">
                <thead className="thead-light">
                    <tr>
                    <th>#</th>
                    <th>Has the research team undergone training in the following as appropriate: </th>
                    <th>YES</th>
                    <th>NO</th>
                    <th>If no please give dates when this will be done</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>Research Ethics /Human Subjects Protection</td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="research_ethics" value="Yes" checked={research_ethics === "Yes"} onChange={(event) => handleInputChange(event, setResearchEthics)} required/>
                    </td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="research_ethics" value="No" checked={research_ethics === "No"} onChange={(event) => handleInputChange(event, setResearchEthics)} required/>
                    </td>
                    <td>
                        {research_ethics === "No" && <input type="date" className={`form-control date_set ${researchEthicsDateError !== ''  ? 'is-invalid' : ''}`} name="research_ethics_date" value={research_ethics_date} onChange={(event) => handleInputDateChange(event, setResearchEthicsDate, setResearchEthicsDateError)} min={today.toISOString().split("T")[0]}/>}
                        {researchEthicsDateError && <div className="invalid-feedback">{researchEthicsDateError}</div>}
                    </td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>ICH-GCP</td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="ich_gcp" value="Yes"  checked={ich_gcp === "Yes"} onChange={(event) => handleInputChange(event, setIchGcp)} required/>
                    </td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="ich_gcp" value="No"  checked={ich_gcp === "No"} onChange={(event) => handleInputChange(event, setIchGcp)} required/>
                    </td>
                    <td>
                        {ich_gcp === "No" && <input type="date" className={`form-control date_set ${ichGcpDateError !== ''  ? 'is-invalid' : ''}`} name="ich_gcp_date" value={ich_gcp_date} onChange={(event) => handleInputDateChange(event, setIchGcpDate, setIchGcpDateError)} min={today.toISOString().split("T")[0]}/>}
                        {ichGcpDateError && <div className="invalid-feedback">{ichGcpDateError}</div>}
                    </td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Good Clinical Laboratory Practices</td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="good_clinical_lab_practices" value="Yes"  checked={good_clinical_lab_practices === "Yes"} onChange={(event) => handleInputChange(event, setGoodClinicalLabPractices)} required/>
                    </td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="good_clinical_lab_practices" value="No"  checked={good_clinical_lab_practices === "No"} onChange={(event) => handleInputChange(event, setGoodClinicalLabPractices)} required/>
                    </td>
                    <td>
                        {good_clinical_lab_practices === "No" && <input type="date" className={`form-control date_set ${goodClinicalLabPracticesDateError !== ''  ? 'is-invalid' : ''}`} name="good_clinical_lab_practices_date" value={good_clinical_lab_practices_date} onChange={(event) => handleInputDateChange(event, setGoodClinicalLabPracticesDate, setGoodClinicalLabPracticesDateError)} min={today.toISOString().split("T")[0]}/>}
                        {goodClinicalLabPracticesDateError && <div className="invalid-feedback">{goodClinicalLabPracticesDateError}</div>}
                    </td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Good Data Management Practices</td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="good_data_management_practices" value="Yes" checked={good_data_management_practices === "Yes"} onChange={(event) => handleInputChange(event, setGoodDataManagementPractices)} required/>
                    </td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="good_data_management_practices" value="No" checked={good_data_management_practices === "No"} onChange={(event) => handleInputChange(event, setGoodDataManagementPractices)} required/>
                    </td>
                    <td>
                        {good_data_management_practices === "No" && <input type="date" className={`form-control date_set ${goodDataManagementPracticesDateError !== ''  ? 'is-invalid' : ''}`} name="good_data_management_practices_date" value={good_data_management_practices_date} onChange={(event) => handleInputDateChange(event, setGoodDataManagementPracticesDate, setGoodDataManagementPracticesDateError)} min={today.toISOString().split("T")[0]}/>}
                        {goodDataManagementPracticesDateError && <div className="invalid-feedback">{goodDataManagementPracticesDateError}</div>}
                    </td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td id='add_other'>Other (Specify if the team has taken any other similar/equivalent training)</td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="other" value="Yes"  checked={other === "Yes"} onChange={(event) => handleInputChange(event, setOther)} required/>
                    </td>
                    <td align="center">
                        <input className="form-check-input" type="radio" name="other" value="No" checked={other === "No"} onChange={(event) => handleInputChange(event, setOther)} required/>
                    </td>
                    <td>
                        {other === "No" && <input type="date" className={`form-control date_set ${otherDateError !== ''  ? 'is-invalid' : ''}`} name="other_date" value={other_date} onChange={(event) => handleInputDateChange(event, setOtherDate, setOtherDateError)}/>}
                        {otherDateError && <div className="invalid-feedback">{otherDateError}</div>}
                    </td>
                    </tr>
                </tbody>
                </table>
                </div>   
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-lg py-2 rounded-1" onClick={onPrev}>
                        Previous
                    </button>
                    <button type="submit" className="btn btn-primary btn-lg py-2 rounded-1">
                        Next
                    </button>
                </div>
            </form>
            
        </>
    )
}

export default Training;